import React, { useState } from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/styles';

import Page from 'components/Page';

import { hasRoles } from 'utils/session';
import Header from './Header';
import Overview from './Overview';
import TeamPerformance from './TeamPerformance';
import FeedbackOverview from './FeedbackOverview';
import moment from '../../mixins/moment';


const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

function Home() {
  const classes = useStyles();

  const [beginDate, setBeginDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment());

  function handleEndDateChange(date) {
    setEndDate(date);
  }

  function handleBeginDateChange(date) {
    setBeginDate(date);
  }

  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container maxWidth="lg">
        <Header onChangeBeginDate={handleBeginDateChange} onChangeEndDate={handleEndDateChange}/>
        {hasRoles('user', 'seller', 'supervisor seller', 'manager seller') && (
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <Overview beginDate={beginDate} endDate={endDate} />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TeamPerformance beginDate={beginDate} endDate={endDate}/>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <FeedbackOverview beginDate={beginDate} endDate={endDate}/>
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
}

export default Home;
