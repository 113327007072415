/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import numeral from 'mixins/numeral';
import gradients from 'utils/gradients';
import { makeStyles } from '@material-ui/styles';

import Avatar from '@material-ui/core/Avatar';
import { Card, Grid, Typography } from '@material-ui/core';
import PhonelinkEraseOutlinedIcon from '@material-ui/icons/PhonelinkEraseOutlined';
import PhoneMissedOutlinedIcon from '@material-ui/icons/PhoneMissedOutlined';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';

import summariesService from 'services/summaries';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  invalidAvatar: {
    marginLeft: theme.spacing(1),
    backgroundImage: gradients.red,
    height: 48,
    width: 48
  },
  positiveAvatar: {
    marginLeft: theme.spacing(1),
    backgroundImage: gradients.green,
    height: 48,
    width: 48
  },
  negativeAvatar: {
    marginLeft: theme.spacing(1),
    backgroundImage: gradients.orange,
    height: 48,
    width: 48
  }
}));

function Overview({ beginDate, endDate, className, ...rest }) {
  const classes = useStyles();

  const [summaries, setSummaries] = useState([
    { type: 'INVALID', count: 0 },
    { type: 'NEGATIVE', count: 0 },
    { type: 'POSITIVE', count: 0 }
  ]);

  useEffect(() => {
    summariesService
      .fetchByResponseType(
        beginDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD')
      )
      .then(response => {
        if (response.data.length > 0) {
          const newSummaries = [...summaries].map(summary => {
            const index = response.data.findIndex(
              data => data.type === summary.type
            );

            return index >= 0 ? response.data[index] : summary;
          });

          setSummaries(newSummaries);
        }
      });
    // eslint-disable-next-line
  }, [beginDate, endDate, setSummaries]);

  // eslint-disable-next-line consistent-return
  function getAvatar(type) {
    if (type === 'INVALID') {
      return (
        <Avatar className={classes.invalidAvatar}>
          <PhonelinkEraseOutlinedIcon />
        </Avatar>
      );
    }

    if (type === 'NEGATIVE') {
      return (
        <Avatar className={classes.negativeAvatar}>
          <PhoneMissedOutlinedIcon />
        </Avatar>
      );
    }

    if (type === 'POSITIVE') {
      return (
        <Avatar className={classes.positiveAvatar}>
          <DoneOutlinedIcon />
        </Avatar>
      );
    }
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="center" container justify="space-between">
        {summaries.map(summary => (
          <Grid
            key={summary.type}
            className={classes.item}
            item
            md={4}
            sm={4}
            xs={12}
          >
            <Typography component="h2" gutterBottom variant="subtitle2">
              FEEDBACK {summary.type.toUpperCase()}
            </Typography>
            <div className={classes.valueContainer}>
              <Typography variant="h3">
                {numeral(summary.count).format('0,0')}
              </Typography>

              {getAvatar(summary.type.toUpperCase())}
            </div>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
}

Overview.propTypes = {
  className: PropTypes.string,
  beginDate: PropTypes.object,
  endDate: PropTypes.object
};

export default Overview;
