import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'mixins/moment';

import { makeStyles } from '@material-ui/styles';
import { Visibility as VisibilityIcon } from '@material-ui/icons';

import SearchDonor from './SeachDonor';
import TablePagination from 'components/TablePagination';
import {
  IconButton, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow
} from '@material-ui/core';

import donorService from '../../services/donor';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    margin: theme.spacing(0)
  },
  content: {
    padding: theme.spacing(0)
  }
}));

const ListTodo = (props) => {
  const classes = useStyles();

  const { selected, onSelected } = props;

  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [donors, setDonors] = useState([]);
  const [meta, setMeta] = useState({});

  function fetchCallerTodoList(page = 1, query = '') {
    setIsLoading(true);

    donorService.fetchCallerTodoList(query, page, 5).then((response) => {
      setDonors(response.data);
      setMeta(response.meta);

      setIsLoading(false);
    });
  }

  useEffect(() => {
    let mounted = true;

    function fetchTodos() {
      if (mounted && selected === null) {
        fetchCallerTodoList(1);
      }
    }

    fetchTodos();

    return () => {
      mounted = false;
    };
  }, [setMeta, setDonors, setIsLoading, selected]);

  function handleChangePage(event, nextPage) {
    event.preventDefault();

    fetchCallerTodoList(nextPage);
  }

  function handleSearch(event) {
    event.preventDefault();

    fetchCallerTodoList(1, keyword);
  }

  function handleTextFieldKeyDown(event){
    switch (event.key) {
      case 'Enter':
        fetchCallerTodoList(1, keyword);
        break
      default: break
    }
  }

  return (
    <>
      {isLoading && <LinearProgress />}

      <SearchDonor
        onEnter={handleTextFieldKeyDown}
        onSubmit={handleSearch}
        onKeywordChange={event => setKeyword(event.target.value)}
      />

      <Table aria-label="donor table" size="small" className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell align="center">Penelpon</TableCell>
            <TableCell align="center">Nomor</TableCell>
            <TableCell align="center">Name</TableCell>
            <TableCell align="center">Phone</TableCell>
            <TableCell align="center">First Call</TableCell>
            <TableCell align="center">Next Call</TableCell>
            <TableCell align="center">Aksi</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {donors.map((contact) => (
            <TableRow key={contact.id} selected={selected === contact.id}>
              <TableCell>{contact.employee.name.toUpperCase()}</TableCell>
              <TableCell align="center">{contact.donor.identificationNumber}</TableCell>
              <TableCell>{contact.donor.name.toUpperCase()}</TableCell>
              <TableCell>{contact.donor.phone ? contact.donor.phone.number : '-'}</TableCell>
              <TableCell>{contact.firstCallAt ? moment(contact.nextCallAt).format('YYYY-MM-DD HH:mm') : '-'}</TableCell>
              <TableCell>{contact.nextCallAt ? moment(contact.nextCallAt).format('YYYY-MM-DD HH:mm') : '-'}</TableCell>
              <TableCell align="center">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="icon-button-file">
                  <IconButton
                    color="primary"
                    aria-label="show detail"
                    component="span"
                    onClick={(event) => onSelected(event, contact)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </label>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        meta={meta}
        rowsPerPageOptions={[5]}
        onHandleChangePage={handleChangePage}
        onHandleChangeRowsPerPage={() => undefined}
      />
    </>
  );
};

ListTodo.propTypes = {
  selected: PropTypes.string,
  onSelected: PropTypes.func
};

export default ListTodo;
