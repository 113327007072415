import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/styles';
import {AppBar, Card, CardContent, CardHeader, Grid, Tab, Tabs} from '@material-ui/core';

import FileSvg from 'assets/images/files.svg';

import scriptService from 'services/script';

import TabPanel from 'components/TabPanel';
import Autocomplete from 'components/Autocomplete';
import RichEditor from '../../components/RichEditor';
import {convertFromRaw, EditorState} from 'draft-js';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: theme.spacing(2)
  },
  scriptContent: {
    padding: theme.spacing(0)
  },
  item: {
    marginTop: theme.spacing(2)
  },
  image: {
    width: 130
  },
}));

function ProgramHelper({className}) {
  const classes = useStyles();

  const [tab, setTab] = useState(0);
  const [scripts, setScripts] = useState([]);
  const [script, setScript] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  function handleChangeTab(event, newValue) {
    setTab(newValue);
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    let mounted = true;

    function fetchScripts() {
      if (mounted) {
        scriptService.fetchAll().then(({data}) => {
          const newOptions = data.map(script => ({
            id: script.id,
            label: script.title.toUpperCase(),
            data: script
          }));

          setScripts(newOptions);
        });
      }
    }

    fetchScripts();

    return () => {
      mounted = false;
    }
  }, [setScripts]);

  function handleScriptSelected(newValue) {
    setScript(newValue);

    if (newValue !== null) {
      const content = convertFromRaw(newValue.data.scriptRaw);

      setEditorState(
        EditorState.createWithContent(content)
      );
    } else {
      setEditorState(
        EditorState.createEmpty()
      );
    }
  }

  function handleOpenProposal(script) {
    if (script === null) {
      return;
    }

    window.open(script.data.proposal, '__blank');
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Card>
        <CardHeader title='Skrip Penelponan'/>
        <CardContent className={classes.content}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                required
                name="script"
                labelText="Skrip Program"
                options={scripts}
                onChange={(event, name, value) => handleScriptSelected(value)}
                value={script}
              />
            </Grid>
            <Grid item xs={12} className={classes.item}>
              <AppBar position="static" color="default" elevation={1} className={classes.tabs}>
                <Tabs
                  value={tab}
                  onChange={handleChangeTab}
                  aria-label="script tab"
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab label="Script" {...a11yProps(0)} disabled={script === null}/>
                  <Tab label="Audio" {...a11yProps(1)} disabled={script === null}/>
                  <Tab label="Proposal" {...a11yProps(2)} disabled={script === null}/>
                </Tabs>
              </AppBar>
              <TabPanel value={tab} index={0}>
                <RichEditor
                  className={classes.scriptContent}
                  editorState={editorState}
                  onChange={() => undefined}
                />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <Grid container alignContent='center' alignItems='center' justify='center' style={{minHeight: '200px'}}>
                  {script !== null && <audio controls src={script.data.audio}/>}
                </Grid>
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <Grid container alignContent='center' alignItems='center' justify='center' style={{minHeight: '200px'}}>
                  <img
                    alt='Show file'
                    className={classes.image}
                    src={FileSvg}
                    onClick={() => handleOpenProposal(script)}
                  />
                </Grid>
              </TabPanel>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

ProgramHelper.propTypes = {
  donor: PropTypes.object,
  className: PropTypes.string
};

export default ProgramHelper;
