/* eslint-disable react/no-children-prop,arrow-parens */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import {
  CancelOutlined as DeleteOutlineIcon,
  Visibility as VisibilityIcon
} from '@material-ui/icons';


import TablePagination from 'components/TablePagination';
import { IconButton, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import donorService from '../../services/donor';
import moment from '../../mixins/moment';
import SearchDonor from './SeachDonor';

import { useSnackbar } from 'notistack';
import handleHttpError from '../../utils/handleHttpError';
import DisableDonorDialog from '../ListDonor/DisableDonorDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    margin: theme.spacing(0)
  },
  deleteButton: {
    color: theme.palette.error.main
  },
  searchForm: {
    padding: theme.spacing(2)
  }
}));

const ListDonor = (props) => {
  const classes = useStyles();
  const notistack = useSnackbar();

  const { selected, onSelected } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [donors, setDonors] = useState([]);
  const [meta, setMeta] = useState({});
  const [keyword, setKeyword] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [contact, setContact] = useState('');

  function fetchCallerList(page = 1, query = '') {
    setIsLoading(true);

    donorService.fetchCallerList(query, page, 5).then((response) => {
      setDonors(response.data);
      setMeta(response.meta);
      setIsLoading(false);
    });
  }

  function showDisableFrom(event, contact) {
    setDialogOpen(true);

    setContact(contact);
  }

  function handleDisable(event, {label}) {
    setDialogOpen(false);

    handleDisableForm(event, {...contact, reason: label});
  }

  function handleDisableForm(event, { id, reason: invalidReason }) {
    event.preventDefault();

    donorService.disable({ id, invalidReason }).then((response) => {
      const newDonors = [...donors].map((donor) => (id === donor.id ? { ...donor, isInvalid: true } : donor));

      setDonors(newDonors);

      notistack.enqueueSnackbar(response.data.message, {
        variant: 'success'
      });
    }).catch((error) => handleHttpError(error, null, notistack));
  }

  useEffect(() => {
    let mounted = true;

    function fetchDonors() {
      if (mounted && selected === null) {
        fetchCallerList(1);
      }
    }

    fetchDonors();

    return () => {
      mounted = false;
    };
  }, [setMeta, setDonors, selected]);

  function handleChangePage(event, nextPage) {
    event.preventDefault();

    fetchCallerList(nextPage, keyword);
  }

  function handleSearch(event) {
    event.preventDefault();

    fetchCallerList(1, keyword);
  }

  function handleTextFieldKeyDown(event) {
    switch (event.key) {
      case 'Enter':
        fetchCallerList(1, keyword);
        break
      default:
        break
    }
  }

  return (
    <>
      {isLoading && <LinearProgress/>}

      <SearchDonor
        onEnter={handleTextFieldKeyDown}
        onSubmit={handleSearch}
        onKeywordChange={event => setKeyword(event.target.value)}
      />

      <Table aria-label="donor table" size="small" className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell align="center">Penelpon</TableCell>
            <TableCell align="center">Nomor</TableCell>
            <TableCell align="center">Name</TableCell>
            <TableCell align="center">Phone</TableCell>
            <TableCell align="center">First Call</TableCell>
            <TableCell align="center">Next Call</TableCell>
            <TableCell align="center">Aksi</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {donors.map((contact) => (
            <TableRow key={contact.id} selected={selected === contact.id}>
              <TableCell>{contact.employee.name.toUpperCase()}</TableCell>
              <TableCell align="center">{contact.donor.identificationNumber}</TableCell>
              <TableCell>{contact.donor.name.toUpperCase()}</TableCell>
              <TableCell>{contact.donor.phone ? contact.donor.phone.number : '-'}</TableCell>
              <TableCell>{contact.firstCallAt ? moment(contact.nextCallAt).format('YYYY-MM-DD HH:mm') : '-'}</TableCell>
              <TableCell>{contact.nextCallAt ? moment(contact.nextCallAt).format('YYYY-MM-DD HH:mm') : '-'}</TableCell>
              <TableCell align="center">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="icon-button-file">
                  <IconButton
                    color="primary"
                    aria-label="show detail"
                    component="span"
                    onClick={(event) => onSelected(event, contact)}
                    disabled={contact.isInvalid}
                  >
                    <VisibilityIcon/>
                  </IconButton>
                  <IconButton
                    component='span'
                    aria-label='invalid donor'
                    children={<DeleteOutlineIcon/>}
                    className={classes.deleteButton}
                    onClick={event => showDisableFrom(event, contact)}
                    disabled={contact.isInvalid}
                  />
                </label>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        meta={meta}
        rowsPerPageOptions={[5]}
        onHandleChangePage={handleChangePage}
        onHandleChangeRowsPerPage={() => undefined}
      />
      <DisableDonorDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSubmit={handleDisable}
      />
    </>
  );
};

ListDonor.propTypes = {
  selected: PropTypes.string,
  onSelected: PropTypes.func
};

export default ListDonor;
