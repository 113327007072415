/* eslint-disable object-curly-spacing,arrow-parens,quotes,arrow-body-style,quote-props */
import axios from 'utils/axios';

export default {
  fetchCallerList: (query, page = 1, perPage = 5, team = 'PHONESELLING') => {
    const params = `filter[contactable]=daily&include=donor,donor.phone&filter[team]=${team}&q=${query}&page=${page}&limit=${perPage}&sort=-created`;

    return axios.get(`phoneselling/contacts?${params}`).then(({data: response}) => Promise.resolve(response));
  },
  fetchCallerTodoList: (query, page = 1, perPage = 5, team = 'PHONESELLING') => {
    const params = `filter[contactable]=todo&include=donor,donor.phone&filter[team]=${team}&q=${query}&page=${page}&limit=${perPage}&sort=next`;

    return axios.get(`phoneselling/contacts?${params}`).then(({data: response}) => Promise.resolve(response));
  },
  fetchList: (query, page = 1, perPage = 15, team = 'PHONESELLING') => {
    const params = `include=donor&filter[team]=${team}&q=${query}&page=${page}&limit=${perPage}&sort=-created`;

    return axios.get(`phoneselling/contacts?${params}`).then(({data: response}) => Promise.resolve(response));
  },
  fetchDetailContact: contactId => {
    return axios.get(`phoneselling/contacts/${contactId}`).then(({data: response}) => Promise.resolve(response));
  },
  fetchDetail: donorId => {
    return axios.get(`phoneselling/donors/${donorId}`).then(({data: response}) => Promise.resolve(response));
  },
  upload: data => {
    return axios.post(`/phoneselling/donors/split/upload`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(response => Promise.resolve(response.data));
  },
  downloadTempalet: () => {
    axios.get('/phoneselling/donors/split/template', {
      headers: { 'Accept': 'application/vnd.ms-excel' },
      responseType: 'blob'
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', 'TEMPLATE UPLOAD DONOR.xlsx');

      document.body.appendChild(link);

      link.click();
    });
  },
  disable: payload => {
    const url = `phoneselling/contacts/disable`;

    return axios.patch(url, payload).then(({data: response}) => Promise.resolve(response));
  },
  enable: id => {
    const url = `phoneselling/contacts/enable`;

    return axios.patch(url, {id}).then(({data: response}) => Promise.resolve(response));
  },
};
