import React, { useState } from 'react';

import { makeStyles } from '@material-ui/styles';

import Page from 'components/Page';
import { Grid, LinearProgress } from '@material-ui/core';

import donorService from 'services/donor';

import Header from './Header';
import ListDonor from './ListDonor';
import ContactList from './ContactList';
import DonorDetail from './DonorDetail';
import DonationSummary from './DonationSummary';
import ProgramHelper from './ProgramHelper';
import FeedbackForm from './FeedbackForm';
import ListCaller from './ListCaller';
import ListTodo from './ListTodo';
import Histories from './Histories';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  container: {
    flexGrow: 1
  },
  item: {
    marginTop: theme.spacing(3)
  }
}));

function Caller() {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [donor, setDonor] = useState({});

  function hendleSelected(event, { id, donor: selectedDonor }) {
    setIsLoading(true);

    donorService.fetchDetail(selectedDonor.id).then(({ data }) => {
      setSelected(id);
      setDonor(data);
      setIsLoading(false);
    });
  }

  function handleAfterSubmit() {
    setSelected(null);
    setDonor({});
  }

  return (
    <Page
      className={classes.root}
      title="Penelponan"
    >
      <Header/>
      <div className={classes.container}>
        {isLoading && <LinearProgress/>}
        <Grid container spacing={3}>
          <Grid item md={12}>
            <ListCaller
              dailyList={(
                <ListDonor
                  selected={selected}
                  onSelected={hendleSelected}
                />
              )}
              todoList={(
                <ListTodo
                  selected={selected}
                  onSelected={hendleSelected}
                />
              )}
              onChangeTab={() => setSelected(null)}
            />
          </Grid>
          <Grid item md={6}>
            <DonorDetail
              donor={donor}
            />
            <ContactList
              className={classes.item}
              contacts={donor.phones}
            />
          </Grid>
          <Grid item md={6}>
            <DonationSummary
              donorId={donor.id}
            />
            <Histories
              className={classes.item}
              donorId={donor.id}
            />
          </Grid>
          <Grid item md={12}>
            <ProgramHelper/>
          </Grid>
          {selected !== null && (
            <Grid item md={12}>
              <FeedbackForm
                contactId={selected}
                donorId={donor.id}
                phones={donor.phones}
                afterSubmit={handleAfterSubmit}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </Page>
  );
}

export default Caller;
