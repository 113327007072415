import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import numeral from 'mixins/numeral';

import {makeStyles} from '@material-ui/styles';
import {Card, CardContent, CardHeader, Grid, TextField} from '@material-ui/core';

import donationService from 'services/donation';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: theme.spacing(2)
  },
  item: {
    marginTop: theme.spacing(1)
  }
}));

const DonationSummary = ({donorId, className}) => {
  const classes = useStyles();

  const [summaries, setSummaries] = useState([]);

  useEffect(() => {
    fetchSummary(donorId);
  }, [donorId]);

  function fetchSummary(donorId) {
    if(donorId) {
      donationService.fetchSummary(donorId)
        .then(({data}) => setSummaries(data))
    }
  }

  function getValue(status) {
    const index = summaries.findIndex(summary => summary.transactionStatus === status);

    const totalAmount = index !== -1 ? summaries[index]['totalAmount'] : 0;

    return numeral(totalAmount).format('0,0');
  }

  return <Card className={clsx(classes.root, className)}>
    <CardHeader title='Rekap Donasi'/>
    <CardContent className={classes.content}>
      <Grid container spacing={1}>
        <Grid item xs={6} className={classes.item}>
          <TextField
            id='outlined-basic-verified'
            label='Verified'
            variant='outlined'
            fullWidth
            value={getValue('VERIFIED')}
          />
        </Grid>

        <Grid item xs={6} className={classes.item}>
          <TextField
            id='outlined-basic-pending'
            label='Pending'
            variant='outlined'
            fullWidth
            value={getValue('PENDING')}
          />
        </Grid>
      </Grid>
    </CardContent>
  </Card>
};

DonationSummary.propTypes = {
  donorId: PropTypes.string,
  className: PropTypes.string
};

export default DonationSummary;
