import axios from 'utils/axios';

export default {
  program: () => {
    return axios.get('programs').then(({data: response}) => Promise.resolve(response));
  },
  type: () => {
    return axios.get('funding/types').then(({data: response}) => Promise.resolve(response));
  }
}
