import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import uuid from 'uuid';

import {makeStyles} from '@material-ui/styles';
import {TableCell, TableHead as MuiTableHead, TableRow} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
}));

function TableHeader(props) {
  const classes = useStyles();

  const {className, headers, ...rest} = props;

  return (
    <MuiTableHead {...rest} className={clsx(classes.root, className)}>
      <TableRow>
        {headers.map(head => <TableCell key={uuid.v4()} align='center' scope='col'>{head}</TableCell>)}
      </TableRow>
    </MuiTableHead>
  );
}

TableHeader.propTypes = {
  className: PropTypes.string,
  headers: PropTypes.array.isRequired
};

export default TableHeader;
