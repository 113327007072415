/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import React from 'react';
import session from 'utils/session';

import {
  BackupOutlined as UploadIcon,
  DescriptionOutlined as ReportIcon,
  HomeOutlined as HomeIcon,
  InputOutlined as ConfirmationIcon,
  PeopleOutlineOutlined as DonorListIcon,
  PersonOutline as UserIcon,
  PhoneOutlined as PhoneIcon,
  PostAddOutlined as ScriptIcon,
  LockOutlined as LockIcon,
  HelpOutlineOutlined as ActionIcon,
  FeedbackOutlined as FeedbackIcon
} from '@material-ui/icons';

const config =  [
  {
    subheader: 'Menu',
    items: [
      {
        title: 'Dashboard',
        href: '/home',
        icon: HomeIcon,
        roles: [
          'user',
          'seller',
          'validator',
          'administrator',
          'supervisor validator',
          'supervisor seller',
          'manager seller',
          'manager validator'
        ]
      },
      {
        title: 'Penelponan',
        href: '/caller',
        icon: PhoneIcon,
        roles: [
          'seller',
          'validator',
          'administrator',
          'supervisor validator',
          'supervisor seller',
          'manager seller',
          'manager validator'
        ]
      },
      {
        title: 'List Donatur',
        href: '/donor',
        icon: DonorListIcon,
        roles: [
          'seller',
          'seller admin',
          'validator',
          'administrator',
          'supervisor validator',
          'supervisor seller',
          'manager seller',
          'manager validator'
        ]
      },
      {
        title: 'Upload Donatur',
        href: '/donor/upload',
        icon: UploadIcon,
        roles: [
          'administrator',
          'seller admin',
          'supervisor validator',
          'supervisor seller',
          'manager seller',
          'manager validator'
        ]
      },
      {
        title: 'Input Konfirmasi',
        href: '/confirmation',
        icon: ConfirmationIcon,
        roles: [
          'administrator',
          'seller admin',
          'supervisor validator',
          'supervisor seller',
          'manager seller',
          'manager validator'
        ]
      }
    ],
  },
  {
    subheader: 'Laporan',
    items: [
      {
        title: 'Penelponan',
        href: '/report/caller',
        icon: ReportIcon,
        roles: [
          'administrator',
          'seller admin',
          'supervisor validator',
          'supervisor seller',
          'manager seller',
          'manager validator'
        ]
      }
    ],
  },
  {
    subheader: 'Settings',
    items: [
      {
        title: 'Feedback',
        href: '/feedback',
        icon: FeedbackIcon,
        roles: [
          'administrator',
          'seller admin',
          'supervisor validator',
          'supervisor seller',
          'manager seller',
          'manager validator'
        ]
      },
      {
        title: 'Skrip',
        href: '/script',
        icon: ScriptIcon,
        roles: [
          'administrator',
          'seller admin',
          'supervisor validator',
          'supervisor seller',
          'manager seller',
          'manager validator'
        ]
      }
    ],
  },
  {
    subheader: 'Administrator',
    items: [
      {
        title: 'Action',
        href: '/action',
        icon: ActionIcon,
        roles: [
          'administrator'
        ]
      },
      {
        title: 'Role',
        href: '/role',
        icon: LockIcon,
        roles: [
          'administrator'
        ]
      },
      {
        title: 'User',
        href: '/user',
        icon: UserIcon,
        roles: [
          'administrator'
        ]
      }
    ],
  }
];

const navigations = config.map(navigation => {
  navigation.items = [...navigation.items].filter(item => {
    return !item.hasOwnProperty('roles') || session.hasRoles(...item.roles);
  });

  return navigation;
}).filter(navigation => navigation.items.length > 0);

export default navigations;
