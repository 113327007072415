import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/styles';
import {Card, CardContent, Container, Grid} from '@material-ui/core';

import Header from './Header';
import Page from 'components/Page';
import TableFeedback from './TableFeedback';
import queryParams from 'query-string';
import feedbackService from 'services/feedback';
import TablePagination from 'components/TablePagination';
import {useSnackbar} from 'notistack';
import handleHttpError from 'utils/handleHttpError';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  container: {
    flexGrow: 1
  },
  cardContent: {
    padding: theme.spacing(0)
  }
}));

function ListFeedback({history, location}) {
  const classes = useStyles();

  const notistack = useSnackbar();

  const params = queryParams.parse(location.search);
  const query = params.q || '';
  const page = params.page ? parseInt(params.page) : 1;

  const [feedbacks, setFeedbacks] = useState([]);
  const [meta, setMeta] = useState({});

  useEffect(() => {
    let mounted = true;

    function fetch(query, page) {
      if (mounted) {
        fetchList(query, page);
      }
    }

    fetch(query, page);

    return () => {
      mounted = false;
    }

  }, [setMeta, setFeedbacks, query, page]);

  function fetchList(query = '', page = 1) {
    feedbackService.fetchList(query, page).then(({data, meta}) => {
      setFeedbacks(data);
      setMeta(meta);
    });
  }

  function handleChangePage(event, nextPage) {
    event.preventDefault();

    const newParams = {...params, page: nextPage};
    const newLocation = {...location, search: queryParams.stringify(newParams)};

    history.push(newLocation);
  }

  function handleDisable(event, {id}) {
    event.preventDefault();

    feedbackService.disable(id).then(response => {
      const newFeedbacks = [...feedbacks].map(feedback => id === feedback.id ? {...feedback, isActive: false} : feedback);

      setFeedbacks(newFeedbacks);

      notistack.enqueueSnackbar(response.data.message, {
        variant: 'success'
      });
    }).catch(error => handleHttpError(error, null, notistack));
  }

  function handleEnable(event, {id}) {
    event.preventDefault();

    feedbackService.enable(id).then(response => {
      const newFeedbacks = [...feedbacks].map(feedback => id === feedback.id ? {...feedback, isActive: true} : feedback);

      setFeedbacks(newFeedbacks);

      notistack.enqueueSnackbar(response.data.message, {
        variant: 'success'
      });
    }).catch(error => handleHttpError(error, null, notistack));
  }

  return <Page
    className={classes.root}
    title="Feedback"
  >
    <Container maxWidth="lg">
      <Header/>
      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Card>
              <CardContent className={classes.cardContent}>
                <TableFeedback
                  feedbacks={feedbacks}
                  meta={meta}
                  onClickDisable={handleDisable}
                  onClickEnable={handleEnable}
                />
                <TablePagination
                  meta={meta}
                  rowsPerPageOptions={[15]}
                  onHandleChangePage={handleChangePage}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Container>
  </Page>
}

ListFeedback.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default ListFeedback;
