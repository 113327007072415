import axios from 'axios';
import * as Sentry from '@sentry/browser';

import humps from './humps';
import auth from './auth';
import session from './session';

import env from 'mixins/env';

const instance = axios.create({
  baseURL: env.API_URL,
  transformResponse: [
    ...axios.defaults.transformResponse, data => humps.camelizeKeys(data)
  ],
  transformRequest: [
    data => humps.decamelizeKeys(data), ...axios.defaults.transformRequest
  ]
});

instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
instance.defaults.headers.common.Accept = 'application/json';

instance.interceptors.request.use(config => {
    const token = auth.token();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config;
  },
  error => Promise.reject(error)
);

instance.interceptors.response.use(response => response, error => {
  if (axios.isCancel(error)) {
    return Promise.reject(error);
  }

  Sentry.setUser({key: session.user.id, id: session.user.id, email: session.user.email, username: session.user.name});
  Sentry.captureException(error);

  if (!error.hasOwnProperty('response')) {
    return Promise.reject(error);
  }

  if (error.response.status === 401) {
    localStorage.clear();
  }

  return Promise.reject(error);
});

export default instance;
