import decode from 'jwt-decode';
import auth from './auth';

export const defaultSession = () => (
  { name: 'Administrator', permissions: ['default'], roles: ['default'] }
);

export const session = () => {
  const token = auth.token();

  if (token) {
    return decode(token);
  }

  return defaultSession();
};

export const data = () => {
  const {
    id, name, email, roles, employee_id: employeeId, volunteer_id: volunteerId
  } = session();

  return {
    id, name, email, roles, employeeId, volunteerId
  };
};

export const hasRole = (role) => {
  const { roles } = session();

  if (roles) {
    return roles.includes(role);
  }
};

export const hasRoles = (...roles) => roles.some((role) => hasRole(role));

export default {
  session, user: data(), hasRole, hasRoles
};
