import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import env from 'mixins/env';
import { init as Sentry } from '@sentry/browser';
import App from './App';
import * as serviceWorker from './serviceWorker';


if (env.APP_ENV === 'production') {
  Sentry({ dsn: env.SENTRY_DSN });
}

ReactDOM.render(<App/>, document.getElementById('root'));

serviceWorker.unregister();
