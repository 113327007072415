import React from 'react';
import PropTypes from 'prop-types';
import {Card, CardContent, CardHeader, Grid, TextField} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: theme.spacing(2)
  },
  item: {
    marginTop: theme.spacing(1)
  }
}));

const DonorDetail = ({donor, className}) => {
  const classes = useStyles();

  function getValue(property) {
    return (donor && donor[property]) ? donor[property] : '-'
  }

  return <Card className={clsx(classes.root, className)}>
    <CardHeader title='Donatur Detail'/>
    <CardContent className={classes.content}>
      <Grid container spacing={2}>
        <Grid item xs={6} className={classes.item}>
          <TextField
            id='outlined-basic-identification-number'
            label='Donor ID'
            variant='outlined'
            fullWidth
            value={getValue('identificationNumber')}
          />
        </Grid>
        <Grid item xs={6} className={classes.item}>
          <TextField
            id='outlined-basic-type'
            label='Type'
            variant='outlined'
            fullWidth
            value={getValue('type')}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={6} className={classes.item}>
          <TextField
            id='outlined-basic-branch'
            label='Cabang'
            variant='outlined'
            fullWidth
            value={donor.branch ? donor['branch']['name'] : '-'}
          />
        </Grid>
        <Grid item xs={6} className={classes.item}>
          <TextField
            id='outlined-basic-employee'
            label='Marketing'
            variant='outlined'
            fullWidth
            value={donor.employee ? donor['employee']['name'] : '-'}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={6} className={classes.item}>
          <TextField
            id='outlined-basic-name'
            label='Nama'
            variant='outlined'
            fullWidth
            value={getValue('name')}
          />
        </Grid>
        <Grid item xs={6} className={classes.item}>
          <TextField
            id='outlined-basic-email'
            label='Email'
            variant='outlined'
            fullWidth
            value={getValue('email')}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} className={classes.item}>
          <TextField
            id='outlined-basic-address'
            label='Alamat'
            variant='outlined'
            rows={3}
            fullWidth
            multiline
            value={getValue('address')}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={6} className={classes.item}>
          <TextField
            id='outlined-basic-province'
            label='Provinsi'
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grid item xs={6} className={classes.item}>
          <TextField
            id='outlined-basic-city'
            label='Kota'
            variant='outlined'
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={6} className={classes.item}>
          <TextField
            id='outlined-basic-regency'
            label='Kabupaten'
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grid item xs={6} className={classes.item}>
          <TextField
            id='outlined-basic'
            label='Kode Pos'
            variant='outlined'
            fullWidth
            value={getValue('postalCode')}
          />
        </Grid>
      </Grid>
    </CardContent>
  </Card>
};

DonorDetail.propTypes = {
  donor: PropTypes.object,
  className: PropTypes.string
};

export default DonorDetail;
