import numeral from 'numeral';

numeral.register('locale', 'id', {
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  abbreviations: {
    thousand: 'Ribu',
    million: 'Juta',
    billion: 'Milyar',
    trillion: 'Triliun'
  },
  ordinal: function (number) {
    return 'Ke - ' + number.toString()
  },
  currency: {
    symbol: 'Rp. '
  }
});

numeral.locale('id');

export default numeral;
