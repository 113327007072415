import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';

import {
  Button,
  Card,
  CardContent,
  Container,
  FormControl, FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography,
  LinearProgress
} from '@material-ui/core';

import Header from './Header';

import Page from 'components/Page';
import FileDropzone from 'components/FileDropzone';

import MultipleAutocomplete from 'components/MultipleAutocomplete';

import yup from 'mixins/yup';
import {useFormik} from 'formik';
import donorService from 'services/donor';
import employeeService from 'services/employee';

import {useSnackbar} from 'notistack';
import handleHttpError from 'utils/handleHttpError';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  }
}));

function DonorUpload() {
  const classes = useStyles();

  const notistack = useSnackbar();

  const [employees, setEmployees] = useState([]);

  const validationSchema = yup.object().shape({
    officers: yup.array().required(),
    year: yup.string().required(),
    team: yup.string().required(),
    files: yup.array().required()
  });

  const formik = useFormik({
    validateOnChange: false,
    validationSchema,
    initialValues: {
      officers: [],
      year: '',
      team: 'PHONESELLING',
      files: []
    },
    onSubmit: (values, formikHelpers) => {

      const {officers, file, year, team} = {...values, file: values.files[0]};

      const params = new FormData();
      params.append('officers', officers);
      params.append('file', file);
      params.append('year', year);
      params.append('team', team);


      officers.forEach(officer => {
        params.append('officers[]', officer.id);
      });

      donorService.upload(params).then(response => {
        notistack.enqueueSnackbar(response.data.message, {
          variant: 'success'
        });

        formikHelpers.setSubmitting(false);

        formikHelpers.resetForm();
      }).catch(error => handleHttpError(error, formikHelpers, notistack));
    }
  });

  const hasError = field => formik.errors.hasOwnProperty(field);

  const handleRenderOption = option => (
    <Grid container alignItems='center'>
      <Grid item xs>
        <Typography variant='body1' color='textPrimary'>
          {option.label}
        </Typography>
        <Typography variant='caption' color='textSecondary'>
          {option.data.branch.name.toUpperCase()}
        </Typography>
      </Grid>
    </Grid>
  );

  useEffect(() => {
    let mounted = true;

    function fetchEmployee() {
      if (mounted) {
        employeeService.fetchAll().then(({data}) => {
          const employees = data.map(employee => ({id: employee.id, label: employee.name.toUpperCase(), data: employee}));

          setEmployees(employees);
        })
      }
    }

    fetchEmployee();

    return () => {
      mounted = false
    }
  }, [setEmployees]);

  return <Page
    className={classes.root}
    title="Upload Donatur"
  >
    <Container maxWidth="lg">
      <Header/>

      <Card>
        {formik.isSubmitting && <LinearProgress/>}
        <CardContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={hasError('team')}
                >
                  <InputLabel
                    htmlFor="outlined-team"
                    variant="outlined"
                  >
                    Team
                  </InputLabel>
                  <Select
                    native
                    variant="outlined"
                    inputProps={{
                      id: 'outlined-team',
                      name: 'team',
                    }}
                    onChange={formik.handleChange}
                  >
                    <option value="POSITIVE">PHONESELLING</option>
                    <option value="NEGATIVE">VALIDATION</option>
                  </Select>
                  {hasError('team') && <FormHelperText error={hasError('team')}>
                    {formik.errors.team}
                  </FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Tahun"
                  name="year"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.year}
                  error={hasError('year')}
                  helperText={hasError('year') ? formik.errors.year : null}
                />
              </Grid>
              <Grid item xs={12}>
                <MultipleAutocomplete
                  name='officers'
                  labelText="Pilih karyawan"
                  options={employees}
                  value={formik.values.officers}
                  onChange={(event, name, value) => formik.setFieldValue(name, value)}
                  onRenderOption={handleRenderOption}
                  error={hasError('officers')}
                  helperText={hasError('officers') ? formik.errors.officers : null}
                />
              </Grid>
              <Grid item xs={12}>
                <FileDropzone
                  acceptExt=".xls,.xlsx"
                  files={formik.values.files}
                  onChange={file => formik.setFieldValue('files', [].concat(file))}
                  onRemove={() => formik.setFieldValue('files', [])}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant='contained'
                  color='primary'
                  children='Upload'
                  type='submit'
                  disabled={formik.isSubmitting}
                />
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Container>
  </Page>
}

export default DonorUpload
