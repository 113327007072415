import axios from 'utils/axios';

export default {
  fetchList: (query = '', page = 1) => {
    return axios.get(`phoneselling/scripts?page=${page}&q=${query}`).then(({data: response}) => Promise.resolve(response));
  },
  fetchAll: () => {
    return axios.get(`phoneselling/scripts/all`).then(({data: response}) => Promise.resolve(response));
  },
  fetchDetail: id => {
    return axios.get(`phoneselling/scripts/${id}`).then(({data: response}) => Promise.resolve(response));
  },
  store: data => {
    return axios.post(`/phoneselling/scripts`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(response => Promise.resolve(response.data));
  },
  disable: id => {
    const url = `/phoneselling/scripts/disable`;

    return axios.patch(url, {id}).then(({data: response}) => Promise.resolve(response));
  },
  enable: id => {
    const url = `/phoneselling/scripts/enable`;

    return axios.patch(url, {id}).then(({data: response}) => Promise.resolve(response));
  },
}
