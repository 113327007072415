import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {makeStyles} from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardHeader,
  colors,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';

import Label from 'components/Label';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: theme.spacing(2)
  }
}));

const ContactList = ({contacts, className}) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader title='List Contact'/>
      <CardContent className={classes.content}>
        <Table aria-label='donor table' size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Number</TableCell>
              <TableCell align='center'>Primary</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contacts && contacts.map(contact => (
              <TableRow key={contact.id}>
                <TableCell>{contact.number}</TableCell>
                <TableCell align='center'>
                  <Label color={contact.isPrimary ? colors.green[600] : colors.grey[600]} variant='outlined'>
                    {contact.isPrimary ? 'YES' : 'NO'}
                  </Label>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

ContactList.propTypes = {
  contacts: PropTypes.array,
  className: PropTypes.string
};

export default ContactList;
