import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

import numeral from 'mixins/numeral';

import { makeStyles } from '@material-ui/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Divider from '@material-ui/core/Divider';
import TableBody from '@material-ui/core/TableBody';

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    margin: 0,
    padding: 0
  }
}));

function FeedbackOverviewTable({
  status, summaries, className, ...rest
}) {
  const classes = useStyles();

  const newSummaries = [...summaries].filter((data) => data.type === status);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title={`FEEDBACK ${status}`}/>
      <Divider/>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">FEEDBACK</TableCell>
                <TableCell align="center">COUNT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newSummaries.map((history) => (
                <TableRow key={history.response}>
                  <TableCell>{history.response}</TableCell>
                  <TableCell align="right">
                    {numeral(history.count).format('0,0')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
}

FeedbackOverviewTable.propTypes = {
  className: PropTypes.string,
  summaries: PropTypes.array,
  status: PropTypes.oneOf(['NEGATIVE', 'POSITIVE'])
};

export default FeedbackOverviewTable;
