import React, { useState } from 'react';
import clsx from 'clsx';

import PropTypes from 'prop-types';
import session from 'utils/session';

import moment from 'mixins/moment';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import CalendarTodayIcon from '@material-ui/icons/CalendarTodayOutlined';
import { DatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  root: {},
  calendar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  calendarButton: {
    backgroundColor: theme.palette.common.white
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  }
}));

function Header({
  onChangeBeginDate, onChangeEndDate, className, ...rest
}) {
  const classes = useStyles();

  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment());
  const [selectEdge, setSelectEdge] = useState(null);
  const [calendarDate, setCalendarDate] = useState(moment());
  const open = Boolean(selectEdge);

  const handleCalendarOpen = (edge) => {
    setSelectEdge(edge);
  };

  const handleCalendarChange = (date) => {
    setCalendarDate(date);
  };

  const handleCalendarClose = () => {
    setCalendarDate(moment());
    setSelectEdge(null);
  };

  const handleCalendarAccept = (date) => {
    setCalendarDate(moment());

    if (selectEdge === 'start') {
      setStartDate(date);
      // eslint-disable-next-line no-unused-expressions
      onChangeBeginDate && onChangeBeginDate(date);

      if (moment(date).isAfter(endDate)) {
        setEndDate(date);
        // eslint-disable-next-line no-unused-expressions
        onChangeEndDate && onChangeEndDate(date);
      }
    } else {
      setEndDate(date);
      // eslint-disable-next-line no-unused-expressions
      onChangeEndDate && onChangeEndDate(date);

      if (moment(date).isBefore(startDate)) {
        setStartDate(date);
        // eslint-disable-next-line no-unused-expressions
        onChangeBeginDate && onChangeBeginDate(date);
      }
    }

    setSelectEdge(null);
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems="center"
        container
        justify="space-between"
        spacing={3}
      >
        <Grid
          item
          md={6}
          xs={12}
        >
          <Typography
            component="h2"
            gutterBottom
            variant="overline"
          >
            Dashboard
          </Typography>
          <Typography
            component="h1"
            gutterBottom
            variant="h3"
          >
            {session.user.name}
          </Typography>
        </Grid>
        <Grid
          className={classes.calendar}
          item
          lg={6}
          xs={12}
        >
          <ButtonGroup
            variant="contained"
          >
            <Button
              className={classes.calendarButton}
              onClick={() => handleCalendarOpen('start')}
            >
              <CalendarTodayIcon className={classes.calendarTodayIcon}/>
              {startDate.format('DD MMM YYYY')}
            </Button>
            <Button
              className={classes.calendarButton}
              onClick={() => handleCalendarOpen('end')}
            >
              <CalendarTodayIcon className={classes.calendarTodayIcon}/>
              {endDate.format('DD MMM YYYY')}
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>

      <DatePicker
        maxDate={moment()}
        onAccept={handleCalendarAccept}
        onChange={handleCalendarChange}
        onClose={handleCalendarClose}
        open={open}
        style={{ display: 'none' }} // Temporal fix to hide the input element
        value={calendarDate}
        variant="dialog"
      />
    </div>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  onChangeBeginDate: PropTypes.func,
  onChangeEndDate: PropTypes.func
};

export default Header;
