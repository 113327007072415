const toCamel = (s) => {
  return s.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

const toUnderscore = (s) => {
  return s.replace(/\.?([A-Z])/g, function (x, y) {
    return "_" + y.toLowerCase()
  }).replace(/^_/, "")
};

const isArray = function (a) {
  return Array.isArray(a);
};

const isObject = function (o) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

const keysToCamel = function (o) {
  if (isBlob(o)) {
    return o;
  } else if (isObject(o)) {
    const n = {};

    Object.keys(o)
      .forEach((k) => {
        n[toCamel(k)] = keysToCamel(o[k]);
      });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToCamel(i);
    });
  }

  return o;
};

const keysToUnderscore = function (o) {
  if (isFormData(o)) {
    let skip = false;

    const formData = new FormData();

    // eslint-disable-next-line no-unused-vars
    for (let key of o.keys()) {
      if (key.includes('[]')) {
        skip = true;
        break;
      }

      formData.append(toUnderscore(key), o.get(key));
    }

    return skip ? o : formData;
  } else if (isObject(o)) {
    const n = {};

    Object.keys(o)
      .forEach((k) => {
        n[toUnderscore(k)] = keysToUnderscore(o[k]);
      });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToUnderscore(i);
    });
  }

  return o;
};

const isFormData = function (o) {
  return o instanceof FormData
};

const isBlob = function (o) {
  return o instanceof Blob
};

const humps = {
  camelizeKeys: keysToCamel,
  decamelizeKeys: keysToUnderscore
};

export default humps;
