import React, { createRef } from 'react';

import { store } from 'utils/auth';
import { parse } from 'query-string';

import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { renderRoutes } from 'react-router-config';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { Button } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

import { ThemeProvider } from '@material-ui/styles';
import { theme } from './theme';
import routes from './routes';

import ScrollReset from './components/ScrollReset';
import StylesProvider from './components/StylesProvider';

import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/main.scss';

const history = createBrowserHistory();

class App extends React.Component {
  constructor(props) {
    super(props);

    this.extractToken();
  }

  // eslint-disable-next-line consistent-return
  extractToken = () => {
    try {
      const { token } = parse(document.location.search);

      if (token) {
        store(token);

        return token;
      }
    } catch (e) {
      return null;
    }
  };

  render() {
    const notistackRef = createRef();

    return (
      <ThemeProvider theme={theme}>
        <StylesProvider direction="ltr">
          <SnackbarProvider
            ref={notistackRef}
            maxSnack={3}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            action={(key) => (
              <Button
                onClick={() => notistackRef.current.closeSnackbar(key)}
                variant="text"
                children={<span style={{ color: theme.palette.primary.contrastText }}>Tutup</span>}
                size="small"
              />
            )}
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Router history={history}>
                <ScrollReset />
                {renderRoutes(routes)}
              </Router>
            </MuiPickersUtilsProvider>
          </SnackbarProvider>
        </StylesProvider>
      </ThemeProvider>
    );
  }
}

export default App;
