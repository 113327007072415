import session from 'utils/session';
import * as Sentry from '@sentry/browser';
import {isAuthentication} from 'utils/auth';

export function captureException(error) {
  if (isAuthentication()) {
    Sentry.setUser({id: session.id, email: session.email});
  }

  Sentry.captureException(error);
}

export default {
  captureException
}
