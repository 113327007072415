import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {makeStyles} from '@material-ui/styles';
import {ExpandMoreOutlined as ExpandMoreIcon} from '@material-ui/icons';

import TablePagination from 'components/TablePagination';
import {
  Collapse,
  colors,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, TextField
} from '@material-ui/core';

import moment from 'mixins/moment';
import sellingHistoryService from 'services/sellingHistory';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: theme.spacing(0)
  },
  cell: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  detailContent: {
    border: 'none',
    padding: theme.spacing(0),
    '&:last-child': {
      margin: theme.spacing(0),
      padding: theme.spacing(0),
    }
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  selectedCell: {
    backgroundColor: colors.grey['100']
  },
}));

function PhoneCallHistory({donorId, className}) {
  const classes = useStyles();

  const [selected, setSelected] = useState({});
  const [histories, setHistories] = useState([]);
  const [meta, setMeta] = useState({});

  useEffect(() => {
    fetchDonation(donorId)
  }, [donorId]);

  function fetchDonation(donorId, page = 1) {
    if (donorId) {
      sellingHistoryService.fetchListByDonor(donorId, page, 5).then(({data, meta}) => {
        setHistories(data);
        setMeta(meta);
      });
    }
  }

  function handleChangePage(event, nextPage) {
    fetchDonation(donorId, nextPage);
  }

  function handleSelected(event, newSelected) {
    if (newSelected.id === selected.id) {
      setSelected({});
    } else {
      setSelected(newSelected)
    }
  }

  return <React.Fragment>
    <Table className={clsx(classes.root, className)}>
      <TableHead>
        <TableRow>
          <TableCell align='center' scope='col'>TANGGAL</TableCell>
          <TableCell align='center' scope='col'>PETUGAS</TableCell>
          <TableCell align='center' scope='col'>KESIMPULAN</TableCell>
          <TableCell align='center' scope='col'>AKSI</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {histories.map(history => (
          <React.Fragment key={history.id}>
            <TableRow hover className={history.id === selected.id ? classes.selectedCell : ''}>
              <TableCell className={classes.cell} align='center'>
                {moment(history.date).format('DD MMMM YYYY')}
              </TableCell>
              <TableCell className={classes.cell} align='center'>
                {history.officer.name.toUpperCase()}
              </TableCell>
              <TableCell className={classes.cell} align='center'>
                {history.feedback.response.toUpperCase()}
              </TableCell>
              <TableCell align='center' className={classes.cell}>
                <label htmlFor='icon-button-delete-detail'>
                  <IconButton
                    aria-label='delete detail'
                    id='icon-button-delete-detail'
                    onClick={event => handleSelected(event, history)}
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: history.id === selected.id,
                    })}
                  >
                    <ExpandMoreIcon/>
                  </IconButton>
                </label>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4} className={classes.detailContent}>
                <Collapse
                  in={history.id === selected.id}
                  unmountOnExit
                  timeout="auto"
                >
                  <Grid container spacing={2} style={{padding: '16px'}}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        label='Phone Number'
                        variant='outlined'
                        fullWidth
                        name="notes"
                        value={history.number}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        label='Notes'
                        variant='outlined'
                        fullWidth
                        multiline
                        name="notes"
                        value={history.notes}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        label='Jenis Dana Yang di Tawarkan'
                        variant='outlined'
                        fullWidth
                        name="notes"
                        value={history.funding.name}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        label='Program Yang di Tawarkan'
                        variant='outlined'
                        fullWidth
                        name="notes"
                        value={history.programId ? history.program.name : ''}
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
    <TablePagination
      meta={meta}
      rowsPerPageOptions={[5]}
      onHandleChangePage={handleChangePage}
    />
  </React.Fragment>
}

PhoneCallHistory.propTypes = {
  donorId: PropTypes.string,
  className: PropTypes.string
};

export default PhoneCallHistory;
