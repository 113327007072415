import React from 'react';
import PropTypes from 'prop-types';

import {default as Pagination} from '@material-ui/core/TablePagination';


function TablePagination(props) {

  const {meta, hidden, onHandleChangePage, rowsPerPageOptions, onHandleChangeRowsPerPage} = props;

  return <Pagination
    rowsPerPageOptions={rowsPerPageOptions || [15, 25]}
    component='div'
    count={meta.total || 0}
    rowsPerPage={meta.perPage ? parseInt(meta.perPage) : (rowsPerPageOptions ? rowsPerPageOptions[0] : 15)}
    page={meta.currentPage ? meta.currentPage - 1 : 0}
    onChangePage={(event, page) => onHandleChangePage(event, page + 1, parseInt(meta.perPage))}
    onChangeRowsPerPage={event => onHandleChangeRowsPerPage(event, event.target.value)}
    hidden={hidden}
  />
}

TablePagination.propTypes = {
  meta: PropTypes.object.isRequired,
  hidden: PropTypes.bool,
  rowsPerPageOptions: PropTypes.array,
  onHandleChangePage: PropTypes.func,
  onHandleChangeRowsPerPage: PropTypes.func
};

export default TablePagination
