import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import numeral from 'mixins/numeral';
import moment from 'mixins/moment';
import totalAmount from 'utils/totalAmount';

import { makeStyles } from '@material-ui/styles';

import TablePagination from 'components/TablePagination';
import { ExpandMoreOutlined as ExpandMoreIcon } from '@material-ui/icons';
import {
  Collapse,
  colors,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';

import donationService from 'services/donation';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: theme.spacing(0)
  },
  cell: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  detailContent: {
    border: 'none',
    padding: theme.spacing(0),
    '&:last-child': {
      margin: theme.spacing(0),
      padding: theme.spacing(0),
    }
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  selectedCell: {
    backgroundColor: colors.grey['100']
  },
}));

function DonationHistory({ donorId, className }) {
  const classes = useStyles();

  const [selected, setSelected] = useState({});
  const [donations, setDonations] = useState([]);
  const [meta, setMeta] = useState({});

  useEffect(() => {
    fetchDonation(donorId);
  }, [donorId]);

  function fetchDonation(donorId, page = 1) {
    if (donorId) {
      donationService.fetchHistory(donorId, page, 5).then(({ data, meta }) => {
        setDonations(data);
        setMeta(meta);
      });
    }
  }

  function handleChangePage(event, nextPage) {
    fetchDonation(donorId, nextPage);
  }

  function handleSelected(event, donation) {
    if (donation.id === selected.id) {
      setSelected({});
    } else {
      setSelected(donation);
    }
  }

  return (
    <>
      <Table className={clsx(classes.root, className)}>
        <TableHead>
          <TableRow>
            <TableCell align="center" scope="col">NOMOR</TableCell>
            <TableCell align="center" scope="col">TANGGAL</TableCell>
            <TableCell align="center" scope="col">TYPE</TableCell>
            <TableCell align="center" scope="col">AMOUNT</TableCell>
            <TableCell align="center" scope="col">STATUS</TableCell>
            <TableCell align="center" scope="col">AKSI</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {donations.map((donation) => (
            <React.Fragment key={donation.id}>
              <TableRow hover className={donation.id === selected.id ? classes.selectedCell : ''}>
                <TableCell className={classes.cell} align="center">
                  {donation.identificationNumber}
                </TableCell>
                <TableCell className={classes.cell} align="center">
                  {moment(donation.date).format('DD MMMM YYYY')}
                </TableCell>
                <TableCell className={classes.cell} align="center">
                  {donation.transactionType}
                </TableCell>
                <TableCell align="right" className={classes.cell}>
                  {numeral(donation.totalAmount).format('$0,0.00')}
                </TableCell>
                <TableCell className={classes.cell} align="center">
                  {donation.transactionStatus}
                </TableCell>
                <TableCell align="center" className={classes.cell}>
                  <label htmlFor="icon-button-delete-detail">
                <IconButton
                    aria-label="delete detail"
                    id="icon-button-delete-detail"
                    onClick={(event) => handleSelected(event, donation)}
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: donation.id === selected.id,
                    })}
                  >
                    <ExpandMoreIcon/>
                  </IconButton>
              </label>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={6} className={classes.detailContent}>
                  <Collapse
                in={donation.id === selected.id}
                unmountOnExit
                timeout="auto"
              >
                <Grid container>
                    <Grid item md={12}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" scope="col">JENIS DANA</TableCell>
                            <TableCell align="center" scope="col">PROGRAM</TableCell>
                            <TableCell align="center" scope="col">AMOUNT</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {donation.details.map((detail) => (
                            <TableRow hover key={detail.id.toString()}>
                              <TableCell className={classes.cell}>
                                {detail.funding.name}
                              </TableCell>
                              <TableCell className={classes.cell}>
                                {detail.program ? detail.program.name : '-'}
                              </TableCell>
                              <TableCell align="right" className={classes.cell}>
                                {numeral(detail.amount).format('$0,0.00')}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TableCell align="left" colSpan={2}>
                              <Typography color="textPrimary" style={{ fontWeight: 700 }}>
                                Total Donasi
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography color="textPrimary" style={{ fontWeight: 700 }}>
                                {totalAmount(donation.details)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </Grid>
                  </Grid>
              </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        meta={meta}
        rowsPerPageOptions={[5]}
        onHandleChangePage={handleChangePage}
      />
    </>
  );
}

DonationHistory.propTypes = {
  donorId: PropTypes.string,
  className: PropTypes.string
};

export default DonationHistory;
