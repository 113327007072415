import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Card, CardContent, Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: theme.spacing(0),
    '&:last-child': {
      paddingBottom: theme.spacing(0),
    }
  }
}));

function TabPanel(props) {
  const classes = useStyles();

  const {children, value, index, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Card elevation={1}>
        <CardContent className={classes.content}>
          {children}
        </CardContent>
      </Card>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number,
  index: PropTypes.number,
};

export default TabPanel;
