import axios from 'utils/axios';

export default {
  fetchByResponseType: (beginDate, endDate) => axios.get(`phoneselling/summaries/response?begin_date=${beginDate}&end_date=${endDate}`)
    .then(({ data: response }) => Promise.resolve(response)),

  fetchByOfficer: (beginDate, endDate) => axios.get(`phoneselling/summaries/officer?begin_date=${beginDate}&end_date=${endDate}`)
    .then(({ data: response }) => Promise.resolve(response)),

  fetchByFeedback: (beginDate, endDate) => axios.get(`phoneselling/summaries/feedback?begin_date=${beginDate}&end_date=${endDate}`)
    .then(({ data: response }) => Promise.resolve(response)),
};
