/* eslint-disable no-template-curly-in-string */
import * as instance from 'yup';

export let mixed = {
  default: 'This field is invalid',
  required: 'This field is a required',
  oneOf: 'This field must be one of the following values: ${values}',
  notOneOf: 'This field must not be one of the following values: ${values}',
};

export let string = {
  length: 'This field must be exactly ${length} characters',
  min: 'This field must be at least ${min} characters',
  max: 'This field must be at most ${max} characters',
  matches: 'This field must match the following: "${regex}"',
  email: 'This field must be a valid email',
  url: 'This field must be a valid URL',
  trim: 'This field must be a trimmed string',
  lowercase: 'This field must be a lowercase string',
  uppercase: 'This field must be a upper case string',
};

export let number = {
  min: 'This field must be greater than or equal to ${min}',
  max: 'This field must be less than or equal to ${max}',
  lessThan: 'This field must be less than ${less}',
  moreThan: 'This field must be greater than ${more}',
  notEqual: 'This field must be not equal to ${notEqual}',
  positive: 'This field must be a positive number',
  negative: 'This field must be a negative number',
  integer: 'This field must be an integer',
};

export let date = {
  min: 'This field must be later than ${min}',
  max: 'This field must be at earlier than ${max}',
};

export let boolean = {};

export let object = {
  noUnknown: 'This field cannot have keys not specified in the object shape',
};

export let array = {
  min: 'This field must have at least ${min} items',
  max: 'This field must have less than or equal to ${max} items',
};

instance.setLocale({
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
});

export const yup = instance;

export default yup;
