import decode from 'jwt-decode';

export const store = (token) => {
  const decodeToken = decode(token);

  localStorage.setItem('token', token);
  localStorage.setItem('expires_in', decodeToken.exp);
};

export const isTokenExpired = () => {
  const currentTime = Date.now() / 1000;

  const expiresIn = localStorage.getItem('expires_in');

  return expiresIn > currentTime;
};

export const isAuthentication = () => {
  return token() !== false ? isTokenExpired() : false;
};

export const token = () => {
  const token = localStorage.getItem('token');

  if (token !== undefined) {
    return token;
  }

  return false;
};

export const clear = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expires_in');
};

export default {
  store, clear, token, isAuthentication
}
