import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/styles';

import TabPanel from 'components/TabPanel';
import {AppBar, Card, CardContent, CardHeader, Grid, Tab, Tabs} from '@material-ui/core';
import DonationHistory from './DonationHistory';
import PhoneCallHistory from './PhoneCallHistory';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: theme.spacing(0),
    '&:last-child': {
      paddingBottom: theme.spacing(0),
    }
  }
}));

const Histories = ({className, donorId}) => {
  const classes = useStyles();

  const [tab, setTab] = useState(0);

  function handleChangeTab(event, newValue) {
    setTab(newValue);
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader title='Interaksi'/>
      <CardContent className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AppBar position="static" color="default" elevation={1}>
              <Tabs
                value={tab}
                onChange={handleChangeTab}
                aria-label="script tab"
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Donasi" {...a11yProps(0)} />
                <Tab label="Penelponan" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <TabPanel value={tab} index={0}>
              <DonationHistory donorId={donorId}/>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <PhoneCallHistory donorId={donorId}/>
            </TabPanel>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Histories.propTypes = {
  donorId: PropTypes.string,
  className: PropTypes.string
};

export default Histories;
