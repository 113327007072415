import React, {useState} from 'react';

import Header from './Header';
import Page from 'components/Page';
import FileDropzone from 'components/FileDropzone';

import draftToHtml from 'draftjs-to-html';
import RichEditor from 'components/RichEditor';
import {convertToRaw, EditorState} from 'draft-js';

import yup from 'mixins/yup';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import scriptService from 'services/script';
import handleHttpError from 'utils/handleHttpError';

import {makeStyles} from '@material-ui/styles';
import {Button, Card, CardContent, Container, Grid, TextField} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  container: {
    paddingTop: theme.spacing(2),
    flexGrow: 1
  }
}));

function CreateScript() {
  const classes = useStyles();
  const notistack = useSnackbar();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const validationSchema = yup.object().shape({
    title: yup.string().required(),
    script: yup.string().required(),
    scriptRaw: yup.string().required(),
    proposal: yup.array().required(),
    audio: yup.array().required()
  });

  const formik = useFormik({
    validateOnChange: false,
    validationSchema,
    initialValues: {
      title: '',
      script: '',
      scriptRaw: '',
      proposal: [],
      audio: []
    },
    onSubmit: (values, formikHelpers) => {

      const params = new FormData();
      params.append('title', values.title);
      params.append('script', values.script);
      params.append('scriptRaw', JSON.stringify(values.scriptRaw));
      params.append('proposal', values.proposal[0]);
      params.append('audio', values.audio[0]);

      scriptService.store(params).then(response => {
        notistack.enqueueSnackbar(response.data.message, {
          variant: 'success'
        });

        formikHelpers.setSubmitting(false);

        formikHelpers.resetForm();

        setEditorState(EditorState.createEmpty());
      }).catch(error => handleHttpError(error, formikHelpers, notistack));
    }
  });

  function handleEditorChange(newState) {
    setEditorState(newState);

    const scriptRaw = convertToRaw(newState.getCurrentContent());
    const script = draftToHtml(scriptRaw);

    formik.setFieldValue('scriptRaw', scriptRaw);
    formik.setFieldValue('script', script);
  }

  return <Page
    className={classes.root}
    title="Skrip"
  >
    <Container maxWidth="lg">
      <Header/>
      <div className={classes.container}>
        <Card component="form" onSubmit={formik.handleSubmit}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Judul Skrip"
                  name="title"
                  variant="outlined"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item md={12}>
                <RichEditor
                  editorState={editorState}
                  onChange={handleEditorChange}
                />
              </Grid>
              <Grid item md={12}>
                <FileDropzone
                  acceptExt=".pdf"
                  title="Select proposal file."
                  files={formik.values.proposal}
                  onChange={file => formik.setFieldValue('proposal', [].concat(file))}
                  onRemove={() => formik.setFieldValue('proposal', [])}
                />
              </Grid>
              <Grid item md={12}>
                <FileDropzone
                  acceptExt=".mp3"
                  title="Select audio file."
                  files={formik.values.audio}
                  onChange={file => formik.setFieldValue('audio', [].concat(file))}
                  onRemove={() => formik.setFieldValue('audio', [])}
                />
              </Grid>
              <Grid item md={12}>
                <Button
                  variant='contained'
                  color='primary'
                  children='Simpan'
                  type='submit'
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </Container>
  </Page>;
}

export default CreateScript;
