import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import feedbackService from 'services/feedback';

import Autocomplete from 'components/Autocomplete';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core';

function DisableDonorDialog({open, onClose, onSubmit}) {
  const [feedbacks, setFeedbacks] = useState([]);
  const [reason, setReason] = useState('');

  useEffect(() => {
    let mounted = true;

    function fetchReason() {
      if (mounted) {
        feedbackService.fetchInvalidList().then(({data}) => {
          const newOptions = data.map(feedback => ({
            id: feedback.id,
            label: feedback.response.toUpperCase(),
            data: feedback
          }));

          setFeedbacks(newOptions);
        });
      }
    }

    fetchReason();

    return () => {
      mounted = false;
    }
  }, [setFeedbacks]);

  const handleRenderFeedbackOption = option => (
    <Grid container alignItems='center'>
      <Grid item xs>
        <Typography variant='body1' color='textPrimary'>
          {option.label}
        </Typography>
        <Typography variant='caption' color='textSecondary'>
          {option.data.type.toUpperCase()}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Alasan Donatur Invalid</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Silahkan pilih alasan kenapa donatur ini merupakan donatur yang tidak valid untuk di telpon.
        </DialogContentText>
        <Autocomplete
          required
          name="feedback"
          labelText="Alasan donatur invalid"
          options={feedbacks}
          onChange={(event, name, value) => setReason(value)}
          value={reason}
          onRenderOption={handleRenderFeedbackOption}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={event => onSubmit(event, reason)} color="primary">
          Simpan
        </Button>
        <Button onClick={onClose}>
          Tutup
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DisableDonorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

export default DisableDonorDialog;
