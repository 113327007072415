import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {colors, IconButton, Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import TableHeader from 'components/TableHeader';
import Label from '../../components/Label';
import {Link} from 'react-router-dom';
import {
  CheckBoxOutlined as EnableIcon,
  DeleteOutline as DisableIcon,
  EditOutlined as EditIcon,
  VisibilityOutlined as DetailsOutlinedIcon
} from '@material-ui/icons';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {},
  deleteButton: {
    color: theme.palette.error.main
  },
  enableButton: {
    color: theme.palette.primary.main
  },
  cell: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  }
}));

function TableScript({className, scripts, onClickDisable, onClickEnable, ...rest}) {
  const classes = useStyles();

  function handleDisableEnable(event, feedback) {
    if (feedback.isActive) {
      onClickDisable(event, feedback);
    } else {
      onClickEnable(event, feedback);
    }
  }

  return <Table
    {...rest}
    className={clsx(classes.root, className)}
  >
    <TableHeader headers={['Judul', 'Aktif', 'Aksi']}/>
    <TableBody>
      {scripts.map(script => (
        <TableRow key={script.id.toString()}>
          <TableCell className={classes.cell}>{script.title}</TableCell>
          <TableCell align="center" className={classes.cell}>
            <Label
              variant="outlined"
              children={script.isActive ? 'AKTIF' : 'TIDAK AKTIF'}
              color={script.isActive ? colors.indigo[600] : colors.red[600]}
            />
          </TableCell>
          <TableCell align="center" className={classes.cell}>
            <IconButton
              to={`/script/${script.id}`}
              children={<DetailsOutlinedIcon/>}
            />
            <IconButton
              to={`/script/${script.id}/edit`}
              component={Link}
              children={<EditIcon/>}
            />
            <IconButton
              onClick={event => handleDisableEnable(event, script)}
              children={script.isActive ? <DisableIcon/> : <EnableIcon/>}
              className={script.isActive ? classes.deleteButton : classes.enableButton}
            />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
}

TableScript.propTypes = {
  className: PropTypes.string,
  scripts: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  onClickDisable: PropTypes.func,
  onClickEnable: PropTypes.func
};

export default TableScript;
