import React from 'react';

import {Container, Grid} from '@material-ui/core';

import {makeStyles} from '@material-ui/styles';

import {useSnackbar} from 'notistack';

import Header from './Header';
import Page from 'components/Page';
import FeedbackForm from './FeedbackForm';

import feedbackService from 'services/feedback';
import handleHttpError from 'utils/handleHttpError';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  container: {
    flexGrow: 1
  },
  cardContent: {
    padding: theme.spacing(0)
  }
}));

function CreateFeedback() {
  const classes = useStyles();

  const notistack = useSnackbar();

  const initialValues = {
    type: 'POSITIVE',
    response: '',
    isActive: true
  };

  function handleSubmit(values, formikHelpers) {
    feedbackService.store(values).then(response => {
      notistack.enqueueSnackbar(response.data.message, {
        variant: 'success'
      });

      formikHelpers.setSubmitting(false);

      formikHelpers.resetForm();
    }).catch(error => handleHttpError(error, formikHelpers, notistack));
  }

  return <Page
    className={classes.root}
    title="Feedback"
  >
    <Container maxWidth="lg">
      <Header/>
      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <FeedbackForm
              initialValues={initialValues}
              onSubmit={handleSubmit}
            />
          </Grid>
        </Grid>
      </div>
    </Container>
  </Page>
}

export default CreateFeedback;
