import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import summariesService from 'services/summaries';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import FeedbackOverviewTable from './FeedbackOverviewTable';

const useStyles = makeStyles(() => ({
  root: {},
}));

function FeedbackOverview({
  beginDate, endDate, className, ...rest
}) {
  const classes = useStyles();

  const [summaries, setSummaries] = useState([]);

  useEffect(() => {
    summariesService.fetchByFeedback(beginDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')).then((response) => {
      setSummaries(response.data);
    });
  }, [beginDate, endDate, setSummaries]);

  return (
    <Grid
      {...rest}
      container
      spacing={3}
      className={clsx(classes.root, className)}
    >
      <Grid item xs={12} sm={12}>
        <FeedbackOverviewTable summaries={summaries} status="POSITIVE"/>
      </Grid>
      <Grid item xs={12} sm={12}>
        <FeedbackOverviewTable summaries={summaries} status="NEGATIVE"/>
      </Grid>
    </Grid>
  );
}

FeedbackOverview.propTypes = {
  className: PropTypes.string,
  beginDate: PropTypes.object,
  endDate: PropTypes.object,
};

export default FeedbackOverview;
