import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import uuid from 'uuid/v1';
import {useDropzone} from 'react-dropzone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {makeStyles} from '@material-ui/styles';
import {
  colors,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import bytesToSize from 'utils/bytesToSize';

import FileAddSvg from 'assets/images/add_file.svg'
import DeleteOutline from '@material-ui/icons/DeleteOutline';

const useStyles = makeStyles((theme) => ({
  root: {},
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(6),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5
  },
  image: {
    width: 130
  },
  info: {
    marginTop: theme.spacing(1)
  },
  list: {
    maxHeight: 320
  },
}));

function FileDropzone({className, acceptExt, onChange, files, onRemove, title, ...rest}) {
  const classes = useStyles();

  const handleDrop = useCallback((acceptedFiles) => {
    onChange(acceptedFiles);
  }, [onChange]);

  const handleRemove = event => {
    onRemove(event)
  };


  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop: handleDrop,
    accept: acceptExt
  });

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {files.length < 1 && <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: isDragActive
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div>
          <img
            alt="Select file"
            className={classes.image}
            src={FileAddSvg}
          />
        </div>
        <div>
          <Typography
            gutterBottom
            variant="h3"
          >
            {title || 'Select files'}
          </Typography>
          <Typography
            className={classes.info}
            color="textSecondary"
            variant="body1"
          >
            Drop files here or click
            {' '}
            <Link underline="always">browse</Link>
            {' '}
            thorough your machine
          </Typography>
        </div>
      </div>}
      {files.length > 0 && (
        <PerfectScrollbar options={{suppressScrollX: true}}>
          <List className={classes.list}>
            {files.map((file, i) => (
              <ListItem
                divider={i < files.length - 1}
                key={uuid()}
              >
                <ListItemIcon>
                  <FileCopyIcon/>
                </ListItemIcon>
                <ListItemText
                  primary={file.name}
                  primaryTypographyProps={{variant: 'h5'}}
                  secondary={bytesToSize(file.size)}
                />
                <Tooltip title="Delete file">
                  <IconButton edge="end" onClick={handleRemove}>
                    <DeleteOutline/>
                  </IconButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        </PerfectScrollbar>
      )}
    </div>
  );
}

FileDropzone.propTypes = {
  className: PropTypes.string,
  acceptExt: PropTypes.any,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  files: PropTypes.array,
  title: PropTypes.string
};

export default FileDropzone;
