import sentry from 'mixins/sentry';
import transformError from 'utils/transformError';

export default function (error, formikHelpers = null, notistack = null) {
  if (formikHelpers !== null) {
    formikHelpers.setSubmitting(false);
  }

  if (error.response) {
    const errors = transformError(error.response.data.errors);

    const key = Object.keys(errors)[0];

    if (key) {
      if (formikHelpers !== null) {
        formikHelpers.setFieldError(key, errors[key]);
      } else if (notistack !== null) {
        notistack.enqueueSnackbar(errors[key], {
          variant: 'error'
        });
      } else {
        console.error(error);

        sentry.captureException(error);
      }
    } else {
      if (notistack !== null) {
        notistack.enqueueSnackbar(error.response.data.message, {
          variant: 'error'
        });
      } else {
        sentry.captureException(error);
      }
    }
  } else {
    if (notistack !== null) {
      notistack.enqueueSnackbar(error.message, {
        variant: 'error'
      });
    }

    sentry.captureException(error);
  }
}
