import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';

import Page from 'components/Page';

import {
  Card, CardContent, Grid, LinearProgress
} from '@material-ui/core';

import queryParams from 'query-string';
import donorService from 'services/donor';
import TablePagination from 'components/TablePagination';
import handleHttpError from 'utils/handleHttpError';
import { useSnackbar } from 'notistack';
import TableDonor from './TableDonor';
import Header from './Header';
import SearchDonor from '../Caller/SeachDonor';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },

  container: {
    flexGrow: 1
  },
  cardContent: {
    padding: theme.spacing(0)
  }
}));

function ListDonor({ history, location }) {
  const classes = useStyles();

  const notistack = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [donors, setDonors] = useState([]);
  const [meta, setMeta] = useState({});
  const [keyword, setKeyword] = useState('');

  const params = queryParams.parse(location.search);
  const page = params.page ? parseInt(params.page) : 1;
  const query = params.q || '';

  useEffect(() => {
    let mounted = true;

    function fetch(query, page) {
      if (mounted) {
        fetchList(query, page);
      }
    }

    fetch(query, page);

    return () => {
      mounted = false;
    };
  }, [setMeta, setDonors, query, page]);

  function fetchList(query = '', page = 1) {
    setIsLoading(true);

    donorService.fetchList(query, page).then(({ data, meta }) => {
      setDonors(data);
      setMeta(meta);
      setIsLoading(false);
    });
  }

  function handleTextFieldKeyDown(event) {
    switch (event.key) {
      case 'Enter':
        fetchList(keyword, 1);
        break
      default:
        break
    }
  }

  function handleSearch(event) {
    event.preventDefault();

    fetchList(keyword, 1);
  }

  function handleChangePage(event, nextPage) {
    event.preventDefault();

    const newParams = { ...params, page: nextPage };
    const newLocation = { ...location, search: queryParams.stringify(newParams) };

    history.push(newLocation);
  }

  function handleDisable(event, { id, reason: invalidReason }) {
    event.preventDefault();

    donorService.disable({ id, invalidReason }).then((response) => {
      const newDonors = [...donors].map((donor) => (id === donor.id ? { ...donor, isInvalid: true } : donor));

      setDonors(newDonors);

      notistack.enqueueSnackbar(response.data.message, {
        variant: 'success'
      });
    }).catch((error) => handleHttpError(error, null, notistack));
  }

  function handleEnable(event, { id }) {
    event.preventDefault();

    donorService.enable(id).then((response) => {
      const newDonors = [...donors].map((donor) => (id === donor.id ? { ...donor, isInvalid: false } : donor));

      setDonors(newDonors);

      notistack.enqueueSnackbar(response.data.message, {
        variant: 'success'
      });
    }).catch((error) => handleHttpError(error, null, notistack))
  }

  return (
    <Page
      className={classes.root}
      title="List Donatur"
    >
      <Header/>
      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Card>
              {isLoading && <LinearProgress/>}

              <SearchDonor
                onEnter={handleTextFieldKeyDown}
                onSubmit={handleSearch}
                onKeywordChange={event => setKeyword(event.target.value)}
              />

              <CardContent className={classes.cardContent}>
                <TableDonor
                  donors={donors}
                  onClickDisable={handleDisable}
                  onClickEnable={handleEnable}
                />
                <TablePagination
                  meta={meta}
                  rowsPerPageOptions={[15]}
                  onHandleChangePage={handleChangePage}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Page>
  );
}

ListDonor.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default ListDonor;
