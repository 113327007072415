import React from 'react';
import PropTypes from 'prop-types';
import {CircularProgress, Grid, TextField, Typography} from '@material-ui/core';
import {Autocomplete as MuiAutocomplete, createFilterOptions} from '@material-ui/lab';

function MultipleAutocomplete(props) {

  const {name, value, options, labelText, required, onChange, error, helperText, onRenderOption} = props;

  const loading = options.length === 0;

  const filterOptions = createFilterOptions({
    stringify: option => option.label,
  });

  const defaultRenderOption = option => (
    <Grid container alignItems='center'>
      <Grid item xs>
        <Typography variant='body1' color='textPrimary'>
          {option.label}
        </Typography>
      </Grid>
    </Grid>
  );

  const handleRenderOption = option => props.hasOwnProperty('onRenderOption') ? onRenderOption(option) : defaultRenderOption(option);

  return <MuiAutocomplete
    id={`multiple-autocomplete-${name}`}
    multiple
    freeSolo
    value={value}
    loading={loading}
    options={options}
    disabled={loading}
    filterOptions={filterOptions}
    onChange={(event, value) => onChange(event, name, value)}
    getOptionLabel={option => option.hasOwnProperty('label') ? option.label : ''}
    renderOption={handleRenderOption}
    renderInput={params => (<TextField
      {...params}
      required={required}
      fullWidth
      error={error}
      helperText={helperText}
      name={name}
      label={labelText}
      variant='outlined'
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <React.Fragment>
            {loading ? <CircularProgress color='inherit' size={20}/> : null}
            {params.InputProps.endAdornment}
          </React.Fragment>
        )
      }}
    />)}
  />
}

MultipleAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  labelText: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
      data: PropTypes.any
    })
  ).isRequired,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onRenderOption: PropTypes.func,
  required: PropTypes.bool
};

export default MultipleAutocomplete;
