import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {Link} from 'react-router-dom';

import {makeStyles} from '@material-ui/styles';
import {colors, IconButton, Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import {
  CheckBoxOutlined as EnableIcon,
  DeleteOutline as DisableIcon,
  EditOutlined as EditIcon
} from "@material-ui/icons"

import Label from 'components/Label';
import TableHeader from 'components/TableHeader';

const useStyles = makeStyles(theme => ({
  root: {},
  deleteButton: {
    color: theme.palette.error.main
  },
  enableButton: {
    color: theme.palette.secondary.main
  },
  cell: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  }
}));

function TableFeedback({className, feedbacks, onClickDisable, onClickEnable, ...rest}) {
  const classes = useStyles();

  function handleDisableEnable(event, feedback) {
    if (feedback.isActive) {
      onClickDisable(event, feedback);
    } else {
      onClickEnable(event, feedback);
    }
  }

  return (
    <Table
      {...rest}
      className={clsx(classes.root, className)}
    >
      <TableHeader headers={['Tipe', 'Feedback', 'Aktif', 'Aksi']}/>
      <TableBody>
        {feedbacks.map(feedback => (
          <TableRow key={feedback.id.toString()}>
            <TableCell align="center" className={classes.cell}>{feedback.type}</TableCell>
            <TableCell className={classes.cell}>{feedback.response}</TableCell>
            <TableCell align="center" className={classes.cell}>
              <Label
                variant="outlined"
                children={feedback.isActive ? 'AKTIF' : 'TIDAK AKTIF'}
                color={feedback.isActive ? colors.indigo[600] : colors.red[600]}
              />
            </TableCell>
            <TableCell align="center" className={classes.cell}>
              <IconButton
                to={`/feedback/${feedback.id}`}
                component={Link}
                children={<EditIcon/>}
              />
              <IconButton
                onClick={event => handleDisableEnable(event, feedback)}
                children={feedback.isActive ? <DisableIcon/> : <EnableIcon/>}
                className={feedback.isActive ? classes.deleteButton : classes.enableButton}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

TableFeedback.propTypes = {
  className: PropTypes.string,
  feedbacks: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  onClickDisable: PropTypes.func,
  onClickEnable: PropTypes.func
};

export default TableFeedback;
