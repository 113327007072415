import React, {useEffect} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import yup from 'mixins/yup';
import {useFormik} from 'formik';

import {makeStyles} from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Select,
  Switch,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  cardContent: {
    padding: theme.spacing(3)
  }
}));

function FeedbackForm({className, initialValues, onSubmit}) {
  const classes = useStyles();

  const validationSchema = yup.object().shape({
    type: yup.string().required(),
    response: yup.string().required(),
    isActive: yup.bool().required()
  });

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validationSchema,
    initialValues,
    onSubmit
  });

  const hasError = field => formik.errors.hasOwnProperty(field);

  useEffect(() => {
    formik.setValues(initialValues);

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  return (
    <Card
      className={clsx(classes.root, className)}
      component='form'
      onSubmit={formik.handleSubmit}
    >
      <CardHeader
        title='Tambah Feedback'
      />
      <CardContent
        className={classes.cardContent}
      >
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-type-feedback" variant="outlined">
                Tipe
              </InputLabel>
              <Select
                native
                variant="outlined"
                value={formik.values.type || ''}
                inputProps={{
                  id: 'outlined-type-feedback',
                  name: 'type',
                }}
                onChange={formik.handleChange}
              >
                <option value="POSITIVE">POSITIVE</option>
                <option value="NEGATIVE">NEGATIVE</option>
                <option value="NEGATIVE">INVALID</option>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              id="outlined-basic-response"
              label="Deskirpsi"
              variant="outlined"
              name="response"
              rows={2}
              fullWidth
              multiline
              value={formik.values.response || ''}
              onChange={formik.handleChange}
              error={hasError('response')}
              helperText={hasError('response') ? formik.errors.response : null}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Switch
                name="isActive"
                checked={formik.values.isActive}
                onChange={event => formik.setFieldValue(event.target.name, event.target.checked)}
                color="primary"
                value={formik.values.isActive || true}
                inputProps={{'aria-label': 'primary checkbox'}}
              />
              }
              label="Feedback active"
            />
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              children='Simpan'
              type='submit'
              disabled={formik.isSubmitting}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

FeedbackForm.propTypes = {
  className: PropTypes.string,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default FeedbackForm;
