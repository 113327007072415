import axios from 'utils/axios';

const baseUrl = `phoneselling/actions`;

export default {
  fetchAll: () => {
    const url = `${baseUrl}/all`;

    return axios.get(url).then(({data: response}) => Promise.resolve(response));
  }
}
