import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {makeStyles} from '@material-ui/styles';
import {Button, Card, CardContent, CardHeader, Grid, TextField, Typography} from '@material-ui/core';

import moment from 'mixins/moment';
import {useFormik} from 'formik';
import Autocomplete from 'components/Autocomplete';
import fundingService from 'services/funding';
import feedbackService from 'services/feedback';
import actionService from 'services/action';
import sellingHistoryService from 'services/sellingHistory';
import employeeService from 'services/employee';

import {useSnackbar} from 'notistack';
import handleHttpError from 'utils/handleHttpError';
import {KeyboardDateTimePicker} from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: theme.spacing(2)
  },
  item: {
    marginTop: theme.spacing(1)
  }
}));

function FeedbackForm({contactId, donorId, phones, afterSubmit, className}) {
  const classes = useStyles();

  const notistack = useSnackbar();

  const [numbers, setNumbers] = useState([]);
  const [types, setTypes] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [actions, setActions] = useState([]);
  const [employees, setEmployees] = useState([]);

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    initialValues: {
      phone: {},
      type: {},
      program: {},
      feedback: {},
      action: {},
      notes: '',
      actionData: {
        employee: '',
        message: '',
        nextCallAt: moment().toDate()
      }
    },
    onSubmit: (values, formikHelpers) => {
      const action = {...values.action};

      const defaultData = {
        phoneContactId: contactId,
        donorId: donorId,
        number: values.phone.label,
        feedbackId: values.feedback.id,
        actionId: values.action.id,
        notes: values.notes,
        fundingTypeId: values.type.id,
        programId: values.program.id || ''
      };

      switch (action.data.key) {
        case 'CALLBACK':
          const actionData = {...values.actionData};

          const nextCallAt = moment(actionData.nextCallAt).format('YYYY-MM-DD HH:mm:ss');

          handleSubmitForm({...defaultData, actionData: {nextCallAt}}, formikHelpers);
          break;
        case 'SEND_EMAIL':
          const {employee, message} = {...values.actionData};

          handleSubmitForm({...defaultData, actionData: {employeeId: employee.id, to: employee.email, message}}, formikHelpers);
          break;
        default:
          handleSubmitForm({...defaultData}, formikHelpers);
          break;
      }
    }
  });

  function handleSubmitForm(params, formikHelpers) {
    sellingHistoryService.store(params).then(response => {
      notistack.enqueueSnackbar(response.data.message, {
        variant: 'success'
      });

      formikHelpers.setSubmitting(false);

      formikHelpers.resetForm();

      afterSubmit();
    }).catch(error => handleHttpError(error, formikHelpers, notistack));
  }

  useEffect(() => {
    const newOptions = phones ? phones.map(phone => ({id: phone.id, label: phone.number.trim(), data: phone})) : [];

    setNumbers(newOptions);

    formik.resetForm();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phones]);

  useEffect(() => {
    let mounted = true;

    function fetchEmployee() {
      if (mounted) {
        employeeService.fetchAll().then(({data}) => {
          const employees = data.map(employee => ({
            id: employee.id,
            label: employee.name.toUpperCase(),
            data: employee
          }));

          setEmployees(employees);
        })
      }
    }

    function fetchReason() {
      if (mounted) {
        feedbackService.fetchAll().then(({data}) => {
          const newOptions = data.map(feedback => ({
            id: feedback.id,
            label: feedback.response.toUpperCase(),
            data: feedback
          }));

          setFeedbacks(newOptions);
        });

        actionService.fetchAll().then(({data}) => {
          const newOptions = data.map(action => ({
            id: action.id,
            label: action.description.toUpperCase(),
            data: action
          }));

          setActions(newOptions);
        });
      }
    }

    function fetchFunding() {
      if (mounted) {
        fundingService.type().then(({data}) => {
          const newOptions = data.map(type => ({id: type.id, label: type.name.toUpperCase(), data: type}));

          setTypes(newOptions);
        });

        fundingService.program().then(({data}) => {
          const newOptions = data.map(type => ({id: type.id, label: type.name.toUpperCase(), data: type}));

          setPrograms(newOptions);
        });
      }
    }

    fetchFunding();
    fetchReason();
    fetchEmployee();

    return () => {
      mounted = false;
    }
  }, [setTypes, setPrograms, setEmployees]);

  const renderActionFeedback = key => formik.values.action !== null && formik.values.action.hasOwnProperty('data') && formik.values.action.data.key === key;

  const handleRenderFeedbackOption = option => (
    <Grid container alignItems='center'>
      <Grid item xs>
        <Typography variant='body1' color='textPrimary'>
          {option.label}
        </Typography>
        <Typography variant='caption' color='textSecondary'>
          {option.data.type.toUpperCase()}
        </Typography>
      </Grid>
    </Grid>
  );

  const handleRenderTypeOption = option => (
    <Grid container alignItems='center'>
      <Grid item xs>
        <Typography variant='body1' color='textPrimary'>
          {option.label}
        </Typography>
        <Typography variant='caption' color='textSecondary'>
          {option.data.category.name.toUpperCase()}
        </Typography>
      </Grid>
    </Grid>
  );

  const handleRenderProgramOption = option => (
    <Grid container alignItems='center'>
      <Grid item xs>
        <Typography variant='caption' color='textSecondary'>
          {option.data.category.name.toUpperCase()}
        </Typography>
        <Typography variant='body1' color='textPrimary'>
          {option.label}
        </Typography>
        <Typography variant='caption' color='textSecondary'>
          {option.data.branch.name.toUpperCase()}
        </Typography>
      </Grid>
    </Grid>
  );

  const handleRenderEmployeeOption = option => (
    <Grid container alignItems='center'>
      <Grid item xs>
        <Typography variant='caption' color='textSecondary'>
          {option.data.branch.name.toUpperCase()}
        </Typography>
        <Typography variant='body1' color='textPrimary'>
          {option.label}
        </Typography>
        <Typography variant='caption' color='textSecondary'>
          {option.data.email.toLowerCase()}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader title='Hasil Penelponan'/>
      <CardContent className={classes.content}>
        <Grid container spacing={2} component='form' onSubmit={formik.handleSubmit}>
          <Grid item xs={12} className={classes.item}>
            <Autocomplete
              required
              name="phone"
              labelText="Pilih Handphone"
              options={numbers}
              onChange={(event, name, value) => formik.setFieldValue(name, value)}
              value={formik.values.phone}
            />
          </Grid>
          <Grid item xs={6} className={classes.item}>
            <Autocomplete
              required
              name="type"
              labelText="Jenis Dana"
              options={types}
              onChange={(event, name, value) => formik.setFieldValue(name, value)}
              value={formik.values.type}
              onRenderOption={handleRenderTypeOption}
            />
          </Grid>
          <Grid item xs={6} className={classes.item}>
            <Autocomplete
              name="program"
              labelText="Program"
              options={programs}
              onChange={(event, name, value) => formik.setFieldValue(name, value)}
              value={formik.values.program}
              onRenderOption={handleRenderProgramOption}
            />
          </Grid>
          <Grid item xs={12} className={classes.item}>
            <TextField
              required
              id='outlined-basic-notes'
              label='Notes'
              variant='outlined'
              rows={3}
              fullWidth
              multiline
              name="notes"
              onChange={formik.handleChange}
              value={formik.values.notes}
            />
          </Grid>
          <Grid item xs={6} className={classes.item}>
            <Autocomplete
              required
              name="feedback"
              labelText="Kesimpulan"
              options={feedbacks}
              onChange={(event, name, value) => formik.setFieldValue(name, value)}
              value={formik.values.feedback}
              onRenderOption={handleRenderFeedbackOption}
            />
          </Grid>
          <Grid item xs={6} className={classes.item}>
            <Autocomplete
              required
              name="action"
              labelText="Aksi"
              options={actions}
              onChange={(event, name, value) => formik.setFieldValue(name, value)}
              value={formik.values.action}
            />
          </Grid>
          {renderActionFeedback('CALLBACK') &&
          <Grid item xs={12} className={classes.item}>
            <KeyboardDateTimePicker
              fullWidth
              autoOk
              disablePast
              ampm={false}
              inputVariant="outlined"
              label="Jadwal Telpon Selanjutnya"
              format="YYYY-MM-DD HH:mm"
              InputAdornmentProps={{position: "end"}}
              value={formik.values.actionData.nextCallAt}
              onChange={date => formik.setFieldValue('actionData.nextCallAt', date)}
            />
          </Grid>}
          {renderActionFeedback('SEND_EMAIL') &&
          <React.Fragment>
            <Grid item xs={12} className={classes.item}>
              <Autocomplete
                name="actionData.employee"
                labelText="Karyawan"
                options={employees}
                onChange={(event, name, value) => formik.setFieldValue(name, value)}
                onRenderOption={handleRenderEmployeeOption}
                value={formik.values.actionData.employee}
              />
            </Grid>
            <Grid item xs={12} className={classes.item}>
              <TextField
                id='outlined-basic-message'
                label='Pesan'
                variant='outlined'
                rows={3}
                fullWidth
                multiline
                name="actionData.message"
                onChange={formik.handleChange}
                value={formik.values.actionData.message}
              />
            </Grid>
          </React.Fragment>
          }
          <Grid item className={classes.item}>
            <Button
              variant='contained'
              color='primary'
              children='Simpan'
              type='submit'
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

FeedbackForm.propTypes = {
  donorId: PropTypes.string,
  phones: PropTypes.array,
  contactId: PropTypes.string,
  afterSubmit: PropTypes.func,
  className: PropTypes.string
};

export default FeedbackForm;
