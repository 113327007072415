import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import moment from 'mixins/moment';

import {makeStyles} from '@material-ui/styles';

import {
  CancelOutlined as DeleteOutlineIcon,
  CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon,
  Visibility as VisibilityIcon
} from '@material-ui/icons';

import Label from 'components/Label';
import TableHeader from 'components/TableHeader';
import {colors, IconButton, Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import DisableDonorDialog from './DisableDonorDialog';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    margin: theme.spacing(0)
  },
  deleteButton: {
    color: theme.palette.error.main
  },
  enableButton: {
    color: theme.palette.secondary.main
  },
  cell: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  }
}));

function TableDonor({className, donors, onClickDisable, onClickEnable, ...rest}) {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [contact, setContact] = useState('');

  function handleEnable(event, contact) {
    if (contact.isInvalid) {
      onClickEnable(event, contact);
    } else {
      setDialogOpen(true);

      setContact(contact);
    }
  }

  function handleDisable(event, {label}) {
    setDialogOpen(false);

    onClickDisable(event, {...contact, reason: label});
  }

  return (
    <React.Fragment>
      <Table
        {...rest}
        aria-label='donor table'
        className={clsx(classes.root, className)}
      >
        <TableHeader
          headers={['Nomor Donor', 'Cabang', 'Phone Seller', 'Nama', 'Phone', 'Terakhir Telpon', 'Valid', 'Aksi']}/>
        <TableBody>
          {donors.map(contact => (
            <TableRow key={contact.id}>
              <TableCell align='center' className={classes.cell}>{contact.donor.identificationNumber}</TableCell>
              <TableCell className={classes.cell}>{contact.branch.name.toUpperCase()}</TableCell>
              <TableCell className={classes.cell}>{contact.employee.name.toUpperCase()}</TableCell>
              <TableCell className={classes.cell}>{contact.donor.name.toUpperCase()}</TableCell>
              <TableCell
                className={classes.cell}
              >
                {contact.donor.phone ? contact.donor.phone.number : '-'}
              </TableCell>
              <TableCell
                className={classes.cell}
                align="center"
              >
                {contact.lastCallAt ? moment(contact.lastCallAt).format('YYYY-MM-DD') : '-'}
              </TableCell>
              <TableCell
                className={classes.cell}
              >
                <Label
                  className={classes.cell}
                  variant="outlined"
                  children={contact.isInvalid ? 'INVALID' : 'VALID'}
                  color={contact.isInvalid ? colors.red[600] : colors.indigo[600]}
                />
              </TableCell>
              <TableCell align='center' className={classes.cell}>
                <label htmlFor="icon-button-file">
                  <IconButton
                    color='primary'
                    component='span'
                    aria-label='show detail'
                  >
                    <VisibilityIcon/>
                  </IconButton>
                  <IconButton
                    component='span'
                    aria-label='invalid donor'
                    children={contact.isInvalid ? <CheckCircleOutlineOutlinedIcon/> : <DeleteOutlineIcon/>}
                    className={contact.isInvalid ? classes.enableButton : classes.deleteButton}
                    onClick={event => handleEnable(event, contact)}
                  />
                </label>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <DisableDonorDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSubmit={handleDisable}
      />
    </React.Fragment>
  );
}

TableDonor.propTypes = {
  className: PropTypes.string,
  donors: PropTypes.array.isRequired,
  onClickDisable: PropTypes.func,
  onClickEnable: PropTypes.func
};

export default TableDonor;
