/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, {lazy} from 'react';
import {Redirect} from 'react-router-dom';

import BlankLayout from './layouts/Blank';
import DefaultLayout from './layouts/Default';
import HorizontalLayout from './layouts/Horizontal';

import HomeView from './views/Home';
import CallerView from './views/Caller';
import ListFeedbackView from './views/ListFeedback';
import CreateFeedbackView from './views/CreateFeedback';
import DetailFeedbackView from './views/DetailFeedback';
import DonorUploadView from './views/DonorUpload';
import ListDonorView from './views/ListDonor';
import CreateScriptView from './views/CreateScript';
import ListScriptView from './views/ListScript';

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/home"/>
  },
  {
    path: '/auth',
    component: DefaultLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login'))
      },
      {
        component: () => <Redirect to="/errors/error-404"/>
      }
    ]
  },
  {
    path: '/errors',
    component: BlankLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404"/>
      }
    ]
  },
  {
    route: '*',
    component: HorizontalLayout,
    routes: [
      {
        path: '/home',
        exact: true,
        component: HomeView
      },
      {
        path: '/caller',
        exact: true,
        component: CallerView
      },
      {
        path: '/donor',
        exact: true,
        component: ListDonorView
      },
      {
        path: '/donor/upload',
        exact: true,
        component: DonorUploadView
      },
      {
        path: '/feedback',
        exact: true,
        component: ListFeedbackView
      },
      {
        path: '/feedback/create',
        exact: true,
        component: CreateFeedbackView
      },
      {
        path: '/feedback/:id',
        exact: true,
        component: DetailFeedbackView
      },
      {
        path: '/script',
        exact: true,
        component: ListScriptView
      },
      {
        path: '/script/create',
        exact: true,
        component: CreateScriptView
      },
      {
        component: () => <Redirect to="/errors/error-404"/>
      }
    ]
  }
];
