import axios from 'utils/axios';
import session from 'utils/session';
import moment from 'mixins/moment';

const baseUrl = `phoneselling/histories`;

export default {
  store: data => {
    const params = {...data, date: moment().format('YYYY-MM-DD'), officerId: session.user.employeeId};

    return axios.post(baseUrl, params).then(({data: response}) => Promise.resolve(response));
  },
  fetchListByDonor: (donorId, page = 1, perPage = 5) => {
    return axios.get(`${baseUrl}?filter[donor_id]=${donorId}&page=${page}&limit=${perPage}&include=officer,donor,feedback,funding,program`)
      .then(({data: response}) => Promise.resolve(response));
  }
}
