import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Container, Grid} from '@material-ui/core';

import {makeStyles} from '@material-ui/styles';

import Page from 'components/Page';
import Header from 'views/CreateFeedback/Header';
import FeedbackForm from 'views/CreateFeedback/FeedbackForm';

import {useSnackbar} from 'notistack';
import feedbackService from 'services/feedback';
import handleHttpError from 'utils/handleHttpError';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  container: {
    flexGrow: 1
  },
  cardContent: {
    padding: theme.spacing(0)
  }
}));

function DetailFeedback({match}) {
  const classes = useStyles();

  const notistack = useSnackbar();

  const id = match.params.id;

  const [feedback, setFeedback] = useState({
    type: 'POSITIVE',
    response: '',
    isActive: true
  });

  useEffect(() => {
    let mounted = true;

    function fetchDetail(id) {
      feedbackService.fetchDetail(id).then(({data}) => {
        if (mounted) {
          const {id, type, response, isActive} = data;

          setFeedback({id, type, response, isActive})
        }
      })
    }

    fetchDetail(id);

    return () => {
      mounted = false
    }
  }, [id, setFeedback]);

  function handleSubmit(values, formikHelpers) {
    feedbackService.update(values).then(response => {
      notistack.enqueueSnackbar(response.data.message, {
        variant: 'success'
      });

      formikHelpers.setSubmitting(false);

      setFeedback({...feedback, type: values.type, response: values.response, isActive: values.isActive});
    }).catch(error => handleHttpError(error, formikHelpers, notistack));
  }

  return <Page
    className={classes.root}
    title="Feedback"
  >
    <Container maxWidth="lg">
      <Header/>
      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <FeedbackForm
              onSubmit={handleSubmit}
              initialValues={{...feedback}}
            />
          </Grid>
        </Grid>
      </div>
    </Container>
  </Page>
}

DetailFeedback.propTypes = {
  match: PropTypes.object.isRequired
};

export default DetailFeedback;
