import axios from 'utils/axios';

export default {
  fetchSummary: donorId => {
    return axios.get(`donors/donations/summaries?id=${donorId}`)
      .then(({data: response}) => Promise.resolve(response));
  },
  fetchHistory: (donorId, page = 1, perPage = 15) => {
    return axios.get(`donors/donations?id=${donorId}&page=${page}&limit=${perPage}`)
      .then(({data: response}) => Promise.resolve(response));
  }
}
