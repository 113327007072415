import axios from 'utils/axios';

const baseUrl = `phoneselling/feedbacks`;

export default {
  store: data => {
    return axios.post(baseUrl, data).then(({data: response}) => Promise.resolve(response));
  },
  fetchInvalidList: () => {
    const url = `${baseUrl}?page=1&limit=10&filter[type]=INVALID`;

    return axios.get(url).then(({data: response}) => Promise.resolve(response));
  },
  fetchList: (query, page = 1, perPage = 15) => {
    const url = `${baseUrl}?q=${query}&page=${page}&limit=${perPage}`;

    return axios.get(url).then(({data: response}) => Promise.resolve(response));
  },
  fetchAll: () => {
    const url = `${baseUrl}/all`;

    return axios.get(url).then(({data: response}) => Promise.resolve(response));
  },
  disable: id => {
    const url = `${baseUrl}/disable`;
    
    return axios.patch(url, {id}).then(({data: response}) => Promise.resolve(response));
  },
  enable: id => {
    const url = `${baseUrl}/enable`;
    
    return axios.patch(url, {id}).then(({data: response}) => Promise.resolve(response));
  },
  fetchDetail: id => {
    const url = `${baseUrl}/${id}`;

    return axios.get(url).then(({data: response}) => Promise.resolve(response));
  },
  update: data => {
    const url = `${baseUrl}/update`;

    return axios.patch(url, data).then(({data: response}) => Promise.resolve(response));
  }
}
