/* eslint-disable no-unused-vars */
export default function (obj) {
  let errors = {};

  for (let field in obj) {
    if (obj.hasOwnProperty(field)) {
      errors[field] = obj[field][0];
    }
  }

  return errors;
}
