import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';

import TabPanel from 'components/TabPanel';
import {
  AppBar, Card, CardContent, CardHeader, Grid, Tab, Tabs
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: theme.spacing(0),
    '&:last-child': {
      paddingBottom: theme.spacing(0),
    }
  }
}));

const ListCaller = ({ onChangeTab, dailyList, todoList }) => {
  const classes = useStyles();

  const [tab, setTab] = useState(0);

  function handleChangeTab(event, newValue) {
    setTab(newValue);

    onChangeTab(event, newValue);
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Card>
      <CardHeader title="List Donatur" />
      <CardContent className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AppBar position="static" color="default" elevation={1}>
              <Tabs
                value={tab}
                onChange={handleChangeTab}
                aria-label="script tab"
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Daily" {...a11yProps(0)} />
                <Tab label="Todo" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <TabPanel value={tab} index={0}>
              { dailyList }
            </TabPanel>
            <TabPanel value={tab} index={1}>
              { todoList }
            </TabPanel>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ListCaller.propTypes = {
  onChangeTab: PropTypes.func,
  dailyList: PropTypes.node,
  todoList: PropTypes.node
};

export default ListCaller;
