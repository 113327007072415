import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Grid, IconButton, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Search as SearchIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  searchForm: {
    padding: theme.spacing(2)
  }
}));

function SearchDonor({ onKeywordChange, onSubmit, onEnter }) {
  const classes = useStyles();

  const [keyword, setKeyword] = useState('');

  function handleChange(event) {
    setKeyword(event.target.value);

    onKeywordChange && onKeywordChange(event);
  }

  return (
    <Grid container direction="column" spacing={2} className={classes.searchForm}>
      <Grid item>
        <TextField
          required
          label="Keyword"
          variant="outlined"
          fullWidth
          placeholder={"Masukan nomor donor atau nama atau nomor handphone, lalu tekan enter atau klik icon search (kaca pembesar)"}
          name="keyword"
          margin="dense"
          value={keyword}
          onChange={handleChange}
          onKeyDown={onEnter}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size="small" onClick={onSubmit}>
                  <SearchIcon/>
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Grid>
    </Grid>
  )
}

SearchDonor.propTypes = {
  onKeywordChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onEnter: PropTypes.func
};

export default SearchDonor;
