import numeral from 'mixins/numeral';

export default function totalAmount(details, $formatted = true) {
  const total = details.reduce((total, detail) => total + parseFloat(detail.amount), 0);

  if ($formatted === false) {
    return total;
  }

  return numeral(total).format('$0,0.00');
}
