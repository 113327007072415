import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

import numeral from 'mixins/numeral';
import summariesService from 'services/summaries';

import { makeStyles } from '@material-ui/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Divider from '@material-ui/core/Divider';
import TableBody from '@material-ui/core/TableBody';

import Chart from './TeamPerformanceChart';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    margin: 0,
    padding: 0
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
  inner: {
    height: 375,
    minWidth: 500
  },
  chart: {
    padding: theme.spacing(4, 2, 0, 2),
    height: '100%'
  }
}));

function TeamPerformance({
  beginDate, endDate, className, ...rest
}) {
  const classes = useStyles();

  const [summaries, setSummaries] = useState([]);

  useEffect(() => {
    summariesService.fetchByOfficer(beginDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')).then((response) => {
      setSummaries(response.data);
    });
  }, [beginDate, endDate, setSummaries]);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Team Performance"
      />
      <Divider/>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Chart
              className={classes.chart}
              summaries={summaries}
            />
          </div>
          {summaries.length > 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">NAMA</TableCell>
                  <TableCell align="center">INVALID</TableCell>
                  <TableCell align="center">NEGATIVE</TableCell>
                  <TableCell align="center">POSITIVE</TableCell>
                  <TableCell align="center">TOTAL</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {summaries.map((history) => (
                  <TableRow key={history.officerId}>
                    <TableCell>{history.name}</TableCell>
                    <TableCell align="right">
                      {numeral(history.invalid).format('0,0')}
                    </TableCell>
                    <TableCell align="right">
                      {numeral(history.negative).format('0,0')}
                    </TableCell>
                    <TableCell align="right">
                      {numeral(history.positive).format('0,0')}
                    </TableCell>
                    <TableCell align="right">
                      {numeral(history.positive + history.negative + history.invalid).format('0,0')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
}

TeamPerformance.propTypes = {
  className: PropTypes.string,
  beginDate: PropTypes.object,
  endDate: PropTypes.object,
};

export default TeamPerformance;
